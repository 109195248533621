<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="card">
            <div class="card-header card-no-border">
              <h5 class="card-title">CMC Complaints</h5>
            </div>
            <div class="card-body">
              <data-table
                :columns="columns"
                :module="module"
                :items="items"
                :isChangePassword="changePassword"
                :viewable="viewable"
                @deleted="fetch"
              ></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DataTable from "@/components/dataTable.vue";
  
  export default {
    components: {
      DataTable,
    },
    data() {
      return {
        searchValue: "",
        viewable: true,
        columns: [
          {
            text: "Complainant Email",
            value: "user",
            secondValue: "email",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Date",
            value: "created_at",
          },
          {
            text: "Status",
            value: "complaintStatus",
          },
          {
            text: "Reference No.",
            value: "referenceNo",
          },
        ],
        items: [
  
   
  {
    user: {
      name: "Alice Johnson",
      email: "alicejohnson@example.com"
    },
    title: "Service Quality",
    created_at: "2024-03-05",
    complaintStatus: "Active",
    referenceNo: "REF67890"
  },
  {
    user: {
      name: "Bob Williams",
      email: "bobwilliams@example.com"
    },
    title: "Incorrect Billing",
    created_at: "2024-03-02",
    complaintStatus: "Active",
    referenceNo: "REF24680"
  }
],

        module: "complaint",
        loading: false,
      };
    },
    mounted() {
      // this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        const id = localStorage.getItem("uid");
        this.$store
          .dispatch("complaint/getAll", { id })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e.error);
            this.loading = false;
          });
      },
    },
  };
  </script>
  