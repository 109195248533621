<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link v-if="changePassword" to="/admins" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Change Password</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="editUser">
              <div class="form-group row" v-if="!changePassword">
                <div class="col-md-12">
                  <label>Old Password <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.old_password">{{ errors.old_password }}</span>
                  <input type="password" class="form-control form-control-sm mb-3" required
                    v-model="user.oldPassword" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label>New Password <span class="text-danger">*</span></label>
                  <input type="password" class="form-control form-control-sm mb-3" required v-model="user.password" />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <label>Confirm Password <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</span>
                  <input type="password" class="form-control form-control-sm mb-3" required
                    v-model="user.confirmPassword" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      user: {
        password: null,
        oldPassword: null,
        confirmPassword: null,
      },
      roles: [],
      errors: [],
      userId: null,
      loading: false,
      changePassword: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      this.changePassword = true;
    } else {
      this.userId = localStorage.getItem("encrypted_id");
    }
  },
  methods: {
    submit() {
      if (this.user.password != this.user.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match.";
      } else {
        this.errors = [];
        this.loading = true;
        const data = toRaw(this.user);
        if (this.changePassword) {
          delete data.oldPassword;
        }
        const id = this.userId;
        this.$store
          .dispatch("admins/update", { data, id })
          .then((response) => {
            this.loading = false;
            this.$toast.show("Successfuly updated.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.user.password = null;
            this.user.oldPassword = null;
            this.user.confirmPassword = null;
            this.errors = [];
            if (response.success == true) {
              if (!this.changePassword) {
                localStorage.clear();
                this.$router.push('/auth/login');
              }
            }
          })
          .catch((e) => {
            this.errors = e.error;
            this.loading = false;
          });
      }
    },
  },
};
</script>
