<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/department-units" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Department Unit</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addUser">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>First Name <span class="text-danger">*</span></label>
                                    <span class="text-danger d-block" v-if="errors.f_name">
                                        First Name is required
                                    </span>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="admin.f_name" />
                                </div>
                                <div class="col-md-6">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control form-control-sm mb-3" v-model="admin.l_name" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.phone"
                                    @keypress="isNumber($event)" maxlength="11" minlength="11" />
                            </div>
                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" maxlength="13" minlength="13" required/>
                            </div>
                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>
                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <label>Password <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.password">
                                    Password is required
                                </span>
                                <input type="password" class="form-control form-control-sm mb-3" required
                                    v-model="admin.password" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <label>Unit Name</label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.unit_name" />
                            </div>
                            <div class="form-group">
                                <label>Department <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.department_id">
                                    {{ errors.department_id && errors.department_id.length > 0 ? errors.department_id[0] :
                                        '' }}
                                </span>
                                <select class="custom-select mb-3" v-model="admin.department_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="department in departments" :value="department.id">
                                        {{ department.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
    mixins: [isNumber],
    data() {
        return {
            admin: {
                f_name: null,
                l_name: null,
                phone: null,
                email: null,
                password: null,
                unit_name: "",
                cnic: null,
                department_id: "",
                createdBy: localStorage.getItem("uid"),
            },
            createdBy: localStorage.getItem("uid"),
            departments: [],
            role: localStorage.getItem("role"),
            errors: [],
            loading: false,
        };
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        submit() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.admin);
            this.$store
                .dispatch("departmentunit/store", { data })
                .then(() => {
                    this.loading = false;
                    this.$toast.show("Successfuly added.", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.errors = [];
                    Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
                    this.admin.department_id = (this.departments.length === 1 && this.role === "Department-Head") ? this.departments[0].id : "";
                    this.admin.createdBy=this.createdBy;
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchDepartments() {
            this.loading = true;
            this.$store
                .dispatch("department/getAll")
                .then((response) => {
                    this.departments = response.data;
                    if (this.departments.length === 1 && this.role=="Department-Head") {
                        this.admin.department_id = this.departments[0].id;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>
  