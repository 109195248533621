<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-title">All Admins</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/admins/add" v-if="permissions && permissions.includes('admin-Add')" class="btn btn-sm"
                  @blockUser="blockUser" style="background-color: rgb(255, 67, 42); color:white;">Add
                  Admin</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">

            <data-table :columns="columns" :module="module" :items="items" :editable="isedit" :deleteable="isdelete"
              :isAdminBlock="isAdminBlock" :pagination="pagination" @blockUser="blockUser" :searchBar="searchBar"
              :isChangePassword="changePassword" @deleted="fetch"
              @search="fetch" @clear="fetch" @nextPage="nextPage"
              ></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";


export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "f_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
      ],
      items: [],
      module: "admins",
      loading: false,
      changePassword: false,
      isedit: false,
      isdelete: false,
      pagination: true,
      searchBar: true,
      isAdminBlock: false,
      permissions: [],
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isedit = (this.permissions && this.permissions.includes("admin-Edit")) ? true : false;
    this.changePassword = (this.permissions && this.permissions.includes("admin-changePassword")) ? true : false;
    this.isdelete = (this.permissions && this.permissions.includes("admin-Delete")) ? true : false;
    this.isAdminBlock = (this.permissions && this.permissions.includes("adminBlock")) ? true : false;
  },
  methods: {
    fetch(search="") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAll", { search,paginate: true})
        .then((response) => {
          this.items = response.data;
          console.log("itemis",this.items);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
     blockUser(blockData) {
      this.loading = true;
      const id = blockData.id;
      const data = {
        reason: blockData.reason,
        model_type: 'App\\Models\\Admin\\Admin',
        type: blockData.type
      };
      this.$store
        .dispatch("admins/blockAdmin", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.fetch();
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true`).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
