<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/department-officers" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Department Officer</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addUser">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>First Name <span class="text-danger">*</span></label>
                                    <span class="text-danger d-block" v-if="errors.f_name">
                                        First Name is required
                                    </span>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="admin.f_name" />
                                </div>
                                <div class="col-md-6">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control form-control-sm mb-3"
                                        v-model="admin.l_name" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.phone"
                                    @keypress="isNumber($event)" maxlength="11" minlength="11" />
                            </div>
                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" maxlength="13" minlength="13" required />
                            </div>
                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>
                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" autocomplete="off" />
                            </div>
                            <div class="form-group">
                                <label>Password <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.password">
                                    Password is required
                                </span>
                                <input type="password" class="form-control form-control-sm mb-3" required
                                    v-model="admin.password" autocomplete="off" />
                            </div>


                            <div class="form-group row mt-3">
                                <div class="col-md-3">
                                    <img :src="previewProfilePic" style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    " />
                                </div>
                                <div class="col-md-8 mt-3">
                                    <label>Upload Profile Picture</label>
                                    <div class="position-relative">
                                        <input type="file" ref="profilePic" class="position-relative"
                                            accept="image/png, image/jpg, image/jpeg" @change="profilePicSelected"
                                            style="opacity: 0; z-index: 100" />
                                        <button type="button" class="btn btn-primary primary-bg-color position-absolute"
                                            style="left: 0">
                                            Browse...
                                        </button>
                                    </div>
                                </div>
                            </div>




                            <div class="form-group mt-3" v-if="role != 'Department-Head'">
                                <label>District</label>

                                <select class="custom-select mb-3" @change="getDepartments" v-model="districtId"
                                    required>
                                    <option value="">Select...</option>
                                    <option v-for="district in districts" :value="district.id">
                                        {{ district.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Department <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.department_id">
                                    {{ errors.department_id && errors.department_id.length > 0 ? errors.department_id[0]
                                        :
                                        '' }}
                                </span>
                                <select class="custom-select mb-3" v-model="admin.department_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="department in departments" :value="department.id">
                                        {{ department.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Role <span class="text-danger">*</span></label>
                                <select class="custom-select mb-3" v-model="admin.officer_type" required>
                                    <option value="">Select...</option>
                                    <option value="Department-Head" v-if="role != 'Department-Head'">
                                        Department Head
                                    </option>
                                    <option value="Department-FocalPerson">
                                        Department Focal Person
                                    </option>
                                    <option value="Department-Unit">
                                        Department Unit
                                    </option>
                                </select>
                            </div>
                            <div class="form-group" v-if="admin.officer_type == 'Department-Unit'">
                                <label>Unit Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.unit_name"
                                    required />
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import districts from "@/store/modules/districts";

export default {
    mixins: [isNumber],
    data() {
        return {
            admin: {
                f_name: null,
                l_name: null,
                phone: null,
                email: null,
                password: null,
                officer_type: "",
                unit_name: "",
                cnic: null,
                department_id: "",
                profileImage: "",
                createdBy: localStorage.getItem("uid"),
            },
            districtId: "",
            createdBy: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            departments: [],
            districts: [],
            errors: [],
            loading: false,
            previewProfilePic: "",
        };
    },
    mounted() {
        this.fetchDepartments();
        this.fetchAllDistricts();
    },
    methods: {
        submit() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.admin);
            this.$store
                .dispatch("departmentOfficer/store", { data })
                .then(() => {
                    this.loading = false;
                    this.$toast.show("Successfuly added.", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.errors = [];
                    Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
                    this.admin.createdBy = this.createdBy;
                    this.admin.department_id = "";
                    this.previewProfilePic = "";
                    this.admin.officer_type = "";
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchDepartments() {
            this.loading = true;
            this.$store
                .dispatch("department/getAll")
                .then((response) => {
                    if (this.role == 'Department-Head') {
                        this.departments = response.data;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        getDepartments() {
            this.departments = [];
            this.loading = true;
            const id = this.districtId;
            this.$store
                .dispatch("department/getDepartmentsforDistrict", { id })
                .then((response) => {
                    this.departments = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });

        },
        fetchAllDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAllDistricts")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        profilePicSelected(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.admin.profileImage = e.target.result;
                    this.previewProfilePic = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
    },
};
</script>