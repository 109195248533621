<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">

        <!-- <button v-if="role == 'DC' || role == 'super-admin'" class="btn btn-primary btn-sm  float-end"
            @click="updateModal = true">
            Update Status
          </button> -->

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button"
              role="tab" aria-controls="home" aria-selected="true">
              Complaint Detail
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
              role="tab" aria-controls="profile" aria-selected="false">
              Complaint Tracking
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active p-4 bg-white mb-5" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="row">
              <div class="col-md-8">
                <p>
                  {{ complaint.complaint_detail }}
                </p>
              </div>
              <div class="col-md-2">
                <div class="row">
                  <div class="col">
                    <strong style="color: #9394a4">Reference No.</strong>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <template v-if="complaint.referenceNo">
                      <p>{{ complaint.referenceNo }}</p>
                    </template>
                    <template v-else-if="!complaint.referenceNo && role == 'DC'">
                      <a href="#" @click="addRef">Add REF</a>
                    </template>
                    <template v-else>
                      <p>N/A</p>
                    </template>

                  </div>
                </div>
              </div>
              <!-- <div class="col-md-2">
                  <div class="row">
                    <div class="col">
                      <strong style="color: #9394a4">Status</strong>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <span class="badge badge-pill badge-light" style="color: #009f19; font-size: 1.0em;">In-progress</span>
  
  
                    </div>
                  </div>
                </div> -->
            </div>

            <!-- Second Row -->
            <div class="row mt-3">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Complainant Name</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.user_profile.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Title</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.title }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Date</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.created_at }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Department</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.department }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Location</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p>{{ complaint.user_profile.address }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Download Document</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col" v-if="documentUrl">
                        <button type="button" class="btn btn-sm btn-danger rounded" @click="openPdf">
                          <i class="fa fa-download"></i> Download
                        </button>
                      </div>
                      <div class="col" v-else>
                        <p>No document</p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col">
                    <!-- <button type="button" class="btn btn-sm btn-danger rounded">
                        Revert
                      </button> -->
                  </div>
                </div>

                <div class="row mt-2">


                </div>


                <div class="row mt-2">
                  <div class="col" v-if="role == 'Department-Head' && complaint.status == 'Submit'">
                    <button :disabled="complaint.status == 'Complete'" type="button"
                      class="btn btn-sm btn-success rounded" @click="completeStatus = true">
                      <span>Complete</span>
                    </button>
                  </div>


                </div>



              </div>
            </div>
            <!-- Second Row -->




            <div class="row mt-3">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col">
                        <strong style="color: #9394a4">Complainant Stage</strong>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <p v-if="complaint.status == 'close'">Resolution</p>
                        <p v-else>{{ complaint.stage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <!-- Third Row -->
            <div class="col-md-12 mt-5">
              <div class="row">
                <img src="/map.png" alt="Image Description" class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="tab-pane fade p-4 bg-white mb-5" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="comment-widgets m-b-20">

              <button type="button" @click="showCommentModel = true" class="btn btn-primary btn-block">
                Add Comment
              </button>

              <div v-if="tracking.length === 0" class="text-center">
                No action performed on this complaint.
              </div>

              <div v-else>
                <div v-for="(track, index) in tracking" :key="index">
                  <div class="d-flex flex-row comment-row">
                    <div class="p-2 pt-0">
                      <span class="round"><img src="https://i.imgur.com/uIgDDDd.jpg" alt="user" width="50" /></span>
                    </div>
                    <div class="comment-text w-100">
                      <strong>{{ track.name }}</strong>
                      <div class="comment-footer">
                        <span class="date" style="color: #9394a4">{{ track.role }}</span>
                      </div>

                      <p class="m-b-5 m-t-10">
                        {{ track.comment }}
                      </p>

                      <div class="col-md-12" :class="{ 'mt-3': track.comment }">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-body p-3">
                              <div class="row">
                                <div class="col-md-12">
                                  {{ track.activity }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 text-center mt-1">
                                  {{ track.action_at }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" v-if="track.files.length > 0">
                        <div class="col-md-12 mt-1" @click="openTrackingFile(track.files)">
                        <a href="#">  Attachments</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-if="showModal" class="modal-mask" @click="closeModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container">
              <div class="modal-header mt-3" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                <h3 v-if="role !== 'Department-Unit'">Assign</h3>
                <h3 v-else>Submit</h3>


              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form @submit.prevent="assignAdmin">





                        <template
                          v-if="['DC', 'inquiry-officer', 'Department-Head', 'Department-Unit', 'Department-FocalPerson'].indexOf(role) === -1">
                          <div>
                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Country</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                @change="countryChanged" v-model="filterData.countryId" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="country in countries" :value="country.id">
                                  {{ country.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>
                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Province</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="filterData.provinceId" @change="provinceChanged" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="province in provinces" :value="province.id">
                                  {{ province.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>


                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">District</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="filterData.districtId" @change="getDc" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>Fs
                                <option v-for="district in districts" :value="district.id">
                                  {{ district.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>




                          </div>
                        </template>




                        <div class="row" v-if="role == 'DC'">

                          <label for="">Designation</label>
                          <div class="col-md-3">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                value="IO" checked @change="radioChanged">
                              <label class="form-check-label" for="exampleRadios1">
                                IO
                              </label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                value="DH" @change="radioChanged">
                              <label class="form-check-label" for="exampleRadios2">
                                DH
                              </label>
                            </div>
                          </div>

                          <div v-if="dcassignrole.role == 'DH'">

                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select Department</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                @change="getDepartmentHead" v-model="dcassignrole.departmentId" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="department in dcassignrole.departments" :value="department.id">
                                  {{
                                    department.name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>

                          </div>



                        </div>


                        <div class="row" v-if="role == 'inquiry-officer'">


                          <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select Department</label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field"
                              @change="getDepartmentHead" v-model="dcassignrole.departmentId" required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="department in dcassignrole.departments" :value="department.id">
                                {{
                                  department.name }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>


                        </div>


                        <div class="row" v-if="role == 'Department-Head'">



                          <label for="">Designation</label>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                value="unit" checked @change="dhSelectDesignation" v-model="dhassignrole.role">
                              <label class="form-check-label" for="exampleRadios1">
                                Department Unit
                              </label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                value="focal" @change="dhSelectDesignation" v-model="dhassignrole.role">
                              <label class="form-check-label" for="exampleRadios2">
                                Focal Person
                              </label>
                            </div>
                          </div>




                          <div v-if="dhassignrole.role == 'unit' || role == 'Department-FocalPerson'">
                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select Department Unit </label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                id="departmentUnitSelect" @change="getDepartmentUnit($event)" required>
                                <option value="" disabled selected hidden>
                                  Select..
                                </option>
                                <option v-for="admin in admins" :value="admin.id" :key="admin.admin_id">
                                  {{
                                    admin.unit_name }}
                                </option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>
                          </div>





                        </div>


                        <div v-if="role == 'Department-FocalPerson'">
                          <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select Department Unit </label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field" id="departmentUnitSelect"
                              @change="getDepartmentUnit($event)" required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="admin in admins" :value="admin.id" :key="admin.admin_id">
                                {{
                                  admin.unit_name }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>
                        </div>









                        <div v-if="role != 'Department-Unit'">
                          <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Assign To</label>
                          <div class="select-container">
                            <select class="form-control form-control-sm border-0 select-field" v-model="assign.admin_id"
                              required>
                              <option value="" disabled selected hidden>
                                Select..
                              </option>
                              <option v-for="admin in admins" :value="admin.admin.id">
                                {{
                                  admin.admin.email }}
                              </option>

                            </select>
                            <i class="fa fa-caret-down select-icon"></i>
                          </div>
                        </div>










                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Feedback</label>

                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button type="submit" class="btn btn-lg btn-block w-100"
                              style="background-color: #ff432a; color: white">
                              <span v-if="role !== 'Department-Unit'">Assign</span>
                              <span v-else>Submit</span>

                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeModal" class="btn btn-outline-danger btn-lg btn-block">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div v-if="completeStatus" class="modal-mask" @click="closeCompleteModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container">
              <div class="modal-header mt-3" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                Status Update
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form>


                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Feedback</label>

                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input class="form-control form-control-sm border-0" placeholder="Type Here"
                            v-model="assign.comment" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>


                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button @click="CompleteStatus" type="button" class="btn btn-lg btn-block w-100"
                              style="background-color: #ff432a; color: white">
                              Complete
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeCompleteModal"
                              class="btn btn-outline-danger btn-lg btn-block">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>









        <div v-if="updateModal" class="modal-mask" @click="closeUpdateModal">
          <div class="modal-wrapper" @click.stop>
            <div class="modal-container">
              <div class="modal-header mt-3" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                Update Status
              </div>
              <div class="modal-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-8 offset-md-2">
                      <form>
                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Title</label>
                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input class="form-control form-control-sm border-0" v-model="closeStatus.title"
                            placeholder="Type Here" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>

                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Feedback</label>

                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input class="form-control form-control-sm border-0" v-model="closeStatus.feedback"
                            placeholder="Type Here" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>

                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Attachment</label>

                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input type="file" class="form-control form-control-sm border-0" placeholder="Type Here"
                            style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>

                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Status</label>
                        <div class="select-container">
                          <select class="form-control form-control-sm border-0 select-field"
                            v-model="closeStatus.status" required>
                            <option value="" disabled selected>
                              Select...
                            </option>

                            <option value="Close">Close</option>
                            <option v-if="role == 'DC' && complaint.status == 'Complete'" value="assignEO">Assign EO
                            </option>

                          </select>
                          <i class="fa fa-caret-down select-icon"></i>
                        </div>



                        <template v-if="closeStatus.status == 'assignEO' && complaint.status == 'Complete'">
                          <div>
                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select EO</label>
                            <div class="select-container">
                              <select class="form-control form-control-sm border-0 select-field"
                                v-model="closeStatus.eoId" required>
                                <option value="" disabled selected>
                                  Select...
                                </option>

                                <option :value="eo.admin.id" v-for="eo in dcEOs">{{ eo.admin.email }}</option>

                              </select>
                              <i class="fa fa-caret-down select-icon"></i>
                            </div>


                            <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Days</label>

                            <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                              <input class="form-control form-control-sm border-0" v-model="closeStatus.eoDays"
                                placeholder="Type Here" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                            </div>
                          </div>
                        </template>




                        <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Select Date</label>

                        <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                          <input type="datetime-local" v-model="closeStatus.date"
                            class="form-control form-control-sm border-0" placeholder="Type Here" required style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " />
                        </div>

                        <div class="row mt-5">
                          <div class="col-md-8">
                            <button @click="CloseStatusComplaint" type="submit" class="btn btn-lg btn-block w-100"
                              style="background-color: #ff432a; color: white">
                              Update
                            </button>
                          </div>
                          <div class="col-md-3 mt-3 mt-md-0">
                            <button type="button" @click="closeUpdateModal"
                              class="btn btn-outline-danger btn-lg btn-block">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-if="showCommentModel" class="modal-mask" @click="closeCommentModal">
      <div class="modal-wrapper" @click.stop>
        <div class="modal-container">
          <div class="modal-header mt-3" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
            <h3>Add Comment</h3>



          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <form @submit.prevent="addCommentTracking">
                    <label class="col-form-label" style="
                              font-family: sans-serif;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 24px;
                            ">Comment</label>

                    <div class="d-flex align-items-center mb-3" style="
                              border-bottom: 2px solid #ff432a;
                              padding-bottom: 5px;
                            ">
                      <input class="form-control form-control-sm border-0" placeholder="Type Here"
                        v-model="commentTracking" style="
                                border: none;
                                outline: none;
                                box-shadow: none;
                              " required />
                    </div>

                    <div class="row mt-5">
                      <div class="col-md-8">
                        <button type="submit" class="btn btn-lg btn-block w-100"
                          style="background-color: #ff432a; color: white">
                          <span>Save</span>

                        </button>
                      </div>
                      <div class="col-md-3 mt-3 mt-md-0">
                        <button type="button" @click="closeCommentModal"
                          class="btn btn-outline-danger btn-lg btn-block">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      role: localStorage.getItem('role'),
      roles: ['DC', 'inquiry-officer', 'Department-Head', 'Department-FocalPerson', 'Department-Unit'],
      complaint: {
        user_profile: {
          name: "",
          father_name: "",
          cnic: "",
          profession: "",
          address: "",
          phone: "",
        },
        referenceNo: null,
        status: ""
      },
      users: [],
      status: [],
      districtOfficers: [],
      errors: [],
      admins: [],
      tracking: [],
      assign: {
        admin_id: "",
        comment: "",
        complaint_id: ""
      },
      filterData: {
        countryId: "",
        provinceId: "",
        districtId: ""
      },
      dcassignrole: {
        role: "",
        departments: [],
        departmentId: ""
      },
      dhassignrole: {
        role: "unit"
      },
      closeStatus: {
        title: "",
        feedback: "",
        status: "",
        date: "",
        eoId: "",
        eoDays: "",
      },
      countries: [],
      provinces: [],
      districts: [],
      dcEOs: [],
      documentUrl: "",
      complainId: null,
      commentTracking: "",
      loading: false,
      activeTab: "complaint-detail",
      showModal: false,
      updateModal: false,
      completeStatus: false,
      showCommentModel: false,
    };
  },
  mounted() {
    this.complainId = this.$route.params.id;
    this.fetch(this.complainId);
    this.assign.complaint_id = this.complainId;
    this.fetchAdmins(this.complainId);
    this.complaintTracking(this.complainId);
    this.districtEO();
    this.fetchCountry();
  },

  methods: {
    displayValue(value) {
      return value || "N/A";
    },

    fetchCountry() {
      this.loading = true;
      this.$store
        .dispatch("country/getAll")
        .then((response) => {
          this.countries = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    provinceChanged() {
      const id = this.filterData.provinceId;
      this.loading = true;
      this.$store
        .dispatch("districts/getAllDistrictsByProvince", { id })
        .then((response) => {
          this.districts = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },

    countryChanged() {

      const id = this.filterData.countryId;
      this.loading = true;
      this.$store
        .dispatch("province/getAllProvincesByCountry", { id })
        .then((response) => {
          this.provinces = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getDc() {
      const id = this.filterData.districtId;
      this.loading = true;
      this.$store
        .dispatch("districts/getDistrictDC", { id })
        .then((response) => {
          this.assign.admin_id = response.data[0].admin_id;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getDepartmentHead() {

      const id = this.dcassignrole.departmentId;
      this.loading = true;
      this.$store
        .dispatch("complaint/getDepartmentHead", { id })
        .then((response) => {
          this.admins = response.data;
          this.assign.admin_id = response.data[0].admin_id;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    getDepartmentUnit(event) {
      const unitName = event.target.value;
      const selectedAdmin = this.admins.find(admin => admin.unit_name === unitName);
      this.assign.admin_id = selectedAdmin.admin_id;
    },
    assignAdmin() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.assign);
      this.$store
        .dispatch("complaint/storeComplaintStatus", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          this.assign.admin_id = "";
          if (this.role === 'Department-Unit') {
            this.complaint.status = 'Submit';
          } else {
            this.complaint.status = 'Assigned';
          }
          this.showModal = false;
          this.complaintTracking(this.complainId);
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    CompleteStatus() {
      this.loading = true;
      this.errors = [];
      const data = {
        'complaint_id': this.complainId,
        'comment': this.assign.comment
      };
      this.$store
        .dispatch("complaint/storeCompleteStatus", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          this.assign.admin_id = "";
          this.complaint.status = 'Complete';
          this.completeStatus = false;
          this.complaintTracking(this.complainId);
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/getSingle", { id })
        .then((response) => {
          this.complaint = response.data;
          this.documentUrl = this.complaint.media.image_url ? this.complaint.media.image_url : "";
          this.complaint.status = response.data.status;
          if (this.complaint.admin_id === null) {
            this.complaint.admin_id = "";
          }

        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchAdmins(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/getAdmins", { id })
        .then((response) => {
          if (this.role == 'inquiry-officer') {
            this.dcassignrole.departments = response.data;

          }
          else {
            this.admins = response.data;

          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    complaintTracking(id) {
      this.loading = true;
      this.$store
        .dispatch("complaint/complaintTracking", { id })
        .then((response) => {
          this.tracking = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    radioChanged(event) {
      this.loading = true;
      this.errors = [];
      const data = { role: event.target.value };
      console.log("data", data);
      if (data.role == 'IO') {
        this.dcassignrole.role = "";
        this.assign.admin_id = "";
        this.dcassignrole.departmentId = "";
        this.fetchAdmins(this.complainId);
      } else {
        this.admins = [];
        this.dcassignrole.role = 'DH';
        this.loading = true;
        this.$store
          .dispatch("complaint/getDepartments")
          .then((response) => {
            this.dcassignrole.departments = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      }
    },
    dhSelectDesignation(event) {

      const data = { role: event.target.value };

      if (data.role == 'unit') {
        var selectElement = document.getElementById('departmentUnitSelect');
        selectElement.selectedIndex = 0;
        this.assign.admin_id = "";
        this.fetchAdmins(this.complainId);
      } else {
        this.assign.admin_id = "";
        this.admins = [];
        this.loading = true;
        this.$store
          .dispatch("complaint/getDepartmentFocalPersons")
          .then((response) => {
            this.admins = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      }
    },
    CloseStatusComplaint(event) {
      event.preventDefault();
      const data = toRaw(this.closeStatus);
      data.complaint_id = this.complainId;
      this.loading = true;
      this.errors = [];
      this.$store
        .dispatch("complaint/UpdateComplaintStatus", { data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
          Object.keys(this.assign).forEach((key) => (this.assign[key] = null));
          this.assign.admin_id = "";
          if (data.status == 'Close') {
            this.complaint.status = 'Close';
          }
          this.updateModal = false;
          this.complaintTracking(this.complainId);
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });

    },
    addRef(event) {
      event.preventDefault();
      this.loading = true;
      const id = this.complainId;
      this.$store
        .dispatch("complaint/addRefNo", { id })
        .then((response) => {
          this.complaint.referenceNo = response.data;
          this.loading = false;
          this.$toast.show("Successfuly added.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.complaint.stage = "Referral";
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });

    },
    addCommentTracking() {
      this.loading = true;
      const data = {
        'complaint_id': this.complainId,
        'comment': this.commentTracking
      };
      this.$store
        .dispatch("complaint/storeCommentTracking", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.commentTracking = "";
            this.showCommentModel = false;
            this.complaintTracking(this.complainId);
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    closeModal() {
      this.showModal = false;
    },
    closeCompleteModal() {
      this.completeStatus = false;
    },
    closeUpdateModal() {
      this.updateModal = false;
    },
    closeCommentModal() {
      this.showCommentModel = false;
    },
    districtEO() {
      this.$store
        .dispatch("complaint/districtEo")
        .then((response) => {
          this.dcEOs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },

    openPdf() {
      window.open(this.documentUrl, '_blank');
    },

    openTrackingFile(files) {
      files.forEach((item) => {
        const newWindow = window.open(item.media.image_url, "_blank");
      });
    }

  },
};
</script>
<style scoped>
.nav-link.active {
  padding: 15px 80px !important;
  border: 0 !important;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.nav-link {
  background-color: #ddd;
  margin-right: 10px;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  padding: 15px 80px !important;
}

.tab-pane {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.comment-widgets .comment-row:hover {
  background: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}

.comment-widgets .comment-row {
  padding: 15px;
}

.comment-text:hover {
  visibility: hidden;
}

.comment-text:hover {
  visibility: visible;
}

.label {
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%;
}

.round img {
  border-radius: 100%;
}

.label-info {
  background-color: #1976d2;
}

.label-success {
  background-color: green;
}

.label-danger {
  background-color: #ef5350;
}

.action-icons a {
  padding-left: 7px;
  vertical-align: middle;
  color: #99abb4;
}

.action-icons a:hover {
  color: #1976d2;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: 40%;
}

.modal-container {
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  padding: 20px;
}

.modal-default-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #ff432a;
  padding-bottom: 5px;
}

.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 2px solid #ff432a;
  padding-bottom: 5px;
}

.select-field {
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background: transparent;
}

.select-icon {
  color: #ff432a;
  cursor: pointer;
}
</style>