<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
        <div class="card">
            <div class="card-header pb-0">
                <h3>District wise Users </h3>
            </div>
            <div class="card-body apex-chart">
                <div id="donutchart">
                    <apexchart ref="chart" width="420" type="donut" :options="chartOptions" :series="series">
                    </apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';
var secondary = localStorage.getItem('secondary_color') || '#ba895d';
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        series() {
            return this.data.percentages || [];
        },
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                labels: ['Bajour', 'Khyber', 'Mohmand'],
                colors: [
                    '#f09',      
                    '#4286f4',   
                    '#38ef7d',   
                    '#ffba56',   
                    '#ff6b6b'    
                ]
            },

        }

    },


    mounted() {
        this.updateLabels(this.data.districts || []);
    },

    watch: {
        data: {
            handler(newData) {
                this.updateLabels(newData.districts || []);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateLabels(districts) {
            this.chartOptions.labels = districts;
            setTimeout(() => {
                if (this.$refs.chart) {
                    this.$refs.chart.updateOptions({
                        labels: this.chartOptions.labels
                    });
                }
            }, 5);
        }
    }
}
</script>