<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
        <div class="card">
            <div class="card-header pb-0">
                <h3>District wise Complaints </h3>

            </div>
            <div class="card-body apex-chart">
                <div id="piechart">
                    <apexchart ref="chart" width="420" type="pie" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';
var secondary = localStorage.getItem('secondary_color') || '#ba895d';
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        series() {
            return this.data.percentages || [];
        },
    },

    data() {
        return {
            chartOptions: {
                chart: {
                    width: 450,
                    type: 'pie',
                },
                // labels: this.data.districts || [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: [
                    '#493240', // Cherry (Dark)
                    '#373b44', // Blue Dark (Dark)
                    '#0a504a', // Green Dark (Dark)
                    '#a86008', // Orange Dark (Dark)
                    '#4b3b77', // Purple Dark (Dark)
                    '#7b1a23'  // Red Dark (Dark)
                ]
            }
        }
    },

    mounted() {
        this.updateLabels(this.data.districts || []);
    },

    watch: {
        data: {
            handler(newData) {
                this.updateLabels(newData.districts || []);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateLabels(districts) {
            this.chartOptions.labels = districts;
            setTimeout(() => {
                if (this.$refs.chart) {
                    this.$refs.chart.updateOptions({
                        labels: this.chartOptions.labels
                    });
                }
            }, 5);
        }
    }

}
</script>