<template>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header text-center">
              <h3>Enter Verification Code</h3>
            </div>
            <div class="card-body">
              <form @submit.prevent="submitCode">
                <div class="mb-3 text-center">
                  <label for="verificationCode" class="form-label">Please enter the 4-digit code sent to your email</label>
                  <div class="d-flex justify-content-center">
                    <input type="text" maxlength="1" class="form-control code-input text-center mx-1" v-model="code[0]" ref="input0" @input="focusNext(0)" />
                    <input type="text" maxlength="1" class="form-control code-input text-center mx-1" v-model="code[1]" ref="input1" @input="focusNext(1)" />
                    <input type="text" maxlength="1" class="form-control code-input text-center mx-1" v-model="code[2]" ref="input2" @input="focusNext(2)" />
                    <input type="text" maxlength="1" class="form-control code-input text-center mx-1" v-model="code[3]" ref="input3" @input="focusNext(3)" />
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary mt-3">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        code: ['', '', '', ''],
      };
    },
    methods: {
      focusNext(index) {
        if (this.code[index].length === 1 && index < 3) {
          this.$refs[`input${index + 1}`].focus();
        }
      },
      submitCode() {
        const verificationCode = this.code.join('');
        if (verificationCode.length === 4) {
          // Handle the verification code submission
          alert(`Verification code submitted: ${verificationCode}`);
        } else {
          alert('Please enter a 4-digit code.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .code-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
  }
  </style>
  