<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">Track Performance</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-5">
                            <!-- District Selector -->
                            <div class="col-md-3 mb-3"  v-if="role != 'Department-Unit' && role != 'Department-FocalPerson' && role != 'Department-Head' ">
                                <div class="container">
                                    <label for="selectOption1">Select District</label>
                                    <select class="custom-select" v-model="districtId" @change="handleDistrictChange"
                                        name="exampleSelect" id="selectOption1">
                                        <option value="" selected>Select</option>
                                        <option v-for="district in districts" :value="district.id">
                                            {{ district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Registrar Selector -->
                            <div class="col-md-3 mb-3" v-if="role !== 'Registrar' && role !== 'inquiry-officer' && role != 'Department-Unit' && role != 'Department-FocalPerson' && role != 'Department-Head'  ">
                                <div class="container">
                                    <label for="selectOptionRegistrar">Select Registrar</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionRegistrar"
                                        v-model="selectedRegistrar" @change="onSelectChange('Registrar')">
                                        <option :selected="!isSelectedDistrictOfficerValid('Registrar')" value="">
                                            Select
                                        </option>
                                        <option v-for="reg in districtOfficers('Registrar')" :key="reg.admin_id"
                                            :value="reg.admin_id">
                                            {{ reg?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- DC Selector -->
                            <div class="col-md-3 mb-3" v-if="role !== 'DC' && role !== 'inquiry-officer' && role != 'Department-Unit' && role != 'Department-FocalPerson' && role != 'Department-Head'">
                                <div class="container">
                                    <label for="selectOptionDC">Select DC</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionDC"
                                        v-model="selectedDC" @change="onSelectChange('DC')">
                                        <option :selected="!isSelectedDistrictOfficerValid('DC')" value="">
                                            Select
                                        </option>
                                        <option v-for="dc in districtOfficers('DC')" :key="dc.admin_id"
                                            :value="dc.admin_id">
                                            {{ dc?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Inquiry Officer Selector -->
                            <div class="col-md-3 mb-3" v-if="role !== 'inquiry-officer' && role != 'Department-Unit' && role != 'Department-FocalPerson' && role != 'Department-Head'">
                                <div class="container">
                                    <label for="selectOptionIO">Select IO</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionIO"
                                        v-model="selectedIO" @change="onSelectChange('IO')">
                                        <option :selected="!isSelectedDistrictOfficerValid('inquiry-officer')" value="">
                                            Select
                                        </option>
                                        <option v-for="io in districtOfficers('inquiry-officer')" :key="io.admin_id"
                                            :value="io.admin_id">
                                            {{ io?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Department Selector -->
                            <div class="col-md-3 mb-3">
                                <div class="container">
                                    <label for="selectOptionDepartment">Select Department</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionDepartment"
                                        v-model="departmentId" @change="handleDepartmentChange()">
                                        <option value="" selected>Select</option>
                                        <option v-for="department in departments" :key="department.id"
                                            :value="department.id">
                                            {{ department.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Department Head Selector -->
                            <div class="col-md-3 mb-3" v-if="role!= 'Department-Head' && role != 'Department-Unit' && role !='Department-FocalPerson' ">
                                <div class="container">
                                    <label for="selectOptionDepartmentHead">Select Department Head</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionDepartmentHead"
                                        v-model="selectedDepartmentHead" @change="onSelectChange('Department-Head')">
                                        <option value="" selected>Select</option>
                                        <option v-for="dh in departmentOfficers('Department-Head')" :key="dh.admin_id"
                                            :value="dh.admin_id">
                                            {{ dh?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Department Focal Person Selector -->
                            <div class="col-md-3 mb-3" v-if="role != 'Department-Unit' && role !='Department-FocalPerson' ">
                                <div class="container">
                                    <label for="selectOptionFocalPerson">Select Department Focal Person</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionFocalPerson"
                                        v-model="selectedDepartmentFocalPerson"
                                        @change="onSelectChange('Department-FocalPerson')">
                                        <option value="" selected>Select</option>
                                        <option v-for="df in departmentOfficers('Department-FocalPerson')"
                                            :key="df.admin_id" :value="df.admin_id">
                                            {{ df?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- Department Unit Selector -->
                            <div class="col-md-3 mb-3" v-if="role != 'Department-Unit'">
                                <div class="container">
                                    <label for="selectOptionDepartmentUnit">Select Department Unit</label>
                                    <select class="custom-select" name="exampleSelect" id="selectOptionDepartmentUnit"
                                        v-model="selectedDepartmentUnit" @change="onSelectChange('Department-Unit')">
                                        <option value="" selected>Select</option>
                                        <option v-for="du in departmentOfficers('Department-Unit')" :key="du.admin_id"
                                            :value="du.admin_id">
                                            {{ du?.admin?.email }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <!-- Charts -->
                        <topChart v-if="data" :data="data" />
                        <barChart v-if="data.monthlyData" :data="data.monthlyData" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topChart from './topChart.vue'
import barChart from './barChart.vue'
import roles from '@/store/modules/roles';

export default {
    components: {
        topChart, barChart
    },
    data() {
        return {
            loading: false,
            districts: [],
            districtId: "",
            districts_Officers: [],
            department_Officers: [],
            departments: [],
            departmentId: "",
            selectadmin: "",
            data: [],
            selectedRegistrar: "",
            selectedDC: "",
            selectedIO: "",
            selectedDepartmentHead: "",
            selectedDepartmentFocalPerson: "",
            selectedDepartmentUnit: "",
            role: localStorage.getItem('role')
        };
    },
    mounted() {
        this.fetch();
    },



    methods:
    {
        onSelectChange(selectedType) {
            switch (selectedType) {
                case 'Registrar':
                    this.selectedDC = '';
                    this.selectedIO = '';
                    this.selectedDepartmentHead = '';
                    this.selectedDepartmentFocalPerson = '';
                    this.selectedDepartmentUnit = '';
                    this.selectadmin = this.selectedRegistrar;
                    break;
                case 'DC':
                    this.selectedRegistrar = '';
                    this.selectedIO = '';
                    this.selectedDepartmentHead = '';
                    this.selectedDepartmentFocalPerson = '';
                    this.selectedDepartmentUnit = '';
                    this.selectadmin = this.selectedDC;
                    break;
                case 'IO':
                    this.selectedRegistrar = '';
                    this.selectedDC = '';
                    this.selectedDepartmentHead = '';
                    this.selectedDepartmentFocalPerson = '';
                    this.selectedDepartmentUnit = '';
                    this.selectadmin = this.selectedIO;
                    break;

                case 'Department-Head':
                    this.selectedRegistrar = '';
                    this.selectedDC = '';
                    this.selectedIO = '';
                    this.selectedDepartmentFocalPerson = '';
                    this.selectedDepartmentUnit = '';
                    this.selectadmin = this.selectedDepartmentHead;
                    break;
                case 'Department-FocalPerson':
                    this.selectedRegistrar = '';
                    this.selectedDC = '';
                    this.selectedIO = '';
                    this.selectedDepartmentHead = '';
                    this.selectedDepartmentUnit = '';
                    this.selectadmin = this.selectedDepartmentFocalPerson;
                    break;
                case 'Department-Unit':
                    this.selectedRegistrar = '';
                    this.selectedDC = '';
                    this.selectedIO = '';
                    this.selectedDepartmentHead = '';
                    this.selectedDepartmentFocalPerson = '';
                    this.selectadmin = this.selectedDepartmentUnit;
                    break;
            }

            this.fetchComplaintsData();
        },
        fetchComplaintsData() {
            this.loading = true;
            const id = this.selectadmin;
            this.$store
                .dispatch("TrackPerformace/trackPerformance", id)
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });

        },
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("TrackPerformace/getAllAdmins")
                .then((response) => {
                    this.districts = response.data.districts;
                    if (this.role == 'Department-Head' || this.role == 'Department-FocalPerson' || this.role == 'Department-Unit') {
                        this.departments = response.data.departments;
                        this.departmentId = this.departments[0].id;
                        this.handleDepartmentChange();
                    }
                    else if (this.role != 'super-admin' && this.role != 'Department-Head' && this.role != 'Department-Unit' && this.role != 'Department-FocalPerson') {
                        this.districtId = this.districts[0].id;
                        this.handleDistrictChange();
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        handleDistrictChange() {
            const data = {
                districtId: this.districtId,
            };
            this.loading = true;
            this.$store
                .dispatch("TrackPerformace/getAdminData", data)
                .then((response) => {
                    this.districts_Officers = response.data.districtOfficers;
                    this.departments = response.data.departments;
                    this.departmentId = '';

                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        handleDepartmentChange() {
            this.loading = true;
            const id = this.departmentId;
            this.$store
                .dispatch("TrackPerformace/getDepartmentOfficers", id)
                .then((response) => {
                    this.department_Officers = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        districtOfficers(type) {
            if (this.districts_Officers) {
                return this.districts_Officers.filter(officer => officer.officer_type === type);
            } else {
                return [];
            }
        },
        departmentOfficers(type) {
            if (this.department_Officers) {
                return this.department_Officers.filter(officer => officer.officer_type === type);
            } else {
                return [];
            }
        },
        isSelectedDistrictOfficerValid(type) {
            return this.districtOfficers(type).some(reg => reg.admin_id == this.selectadmin);
        }
    }
}


</script>