import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    get({ dispatch, commit },{daterange}) {
      console.log("l",daterange);
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/dashboard/${daterange}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
