<template>
  <div>
    <div ref="editorRef" :style="{ height: editorHeight, maxWidth: editorWidth }"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';



const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  editorHeight: {
    type: String,
    default: '300px'
  },
  editorWidth: {
    type: String,
    default: '100%'
  }
});

const editorContent = ref(props.modelValue);
const editorRef = ref(null);
let editor;

const colors = [
  '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
  '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
  '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
  '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
  '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
  '#663d00', '#666600', '#003700', '#002966', '#3d1466'
];


onMounted(() => {
  editor = new Quill(editorRef.value, {
    theme: 'snow',
    modules: {
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        [{ 'color': colors }],
        ['clean'],
      ],

    },
  });

  editor.root.innerHTML = editorContent.value;
  editor.on('text-change', () => {
    editorContent.value = editor.root.innerHTML;
    emits('update:modelValue', editorContent.value);
  });

  watch(() => props.modelValue, (newValue) => {
    if (newValue !== editor.root.innerHTML) {
      editor.root.innerHTML = newValue;
    }
  });

  watch(editorContent, (newValue) => {
    if (newValue !== editor.root.innerHTML) {
      editor.root.innerHTML = newValue;
    }
  });

});
</script>