<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-6 mt-5 mx-auto">
          <router-link to="/ristricted-access" class="btn btn-sm btn-primary mb-3"
            >Back</router-link
          >
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Add Ristricted Access</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="submit" ref="addUser">
                <div class="form-group row">
                  <div class="col-md-6">
                    <label>First Name <span class="text-danger">*</span></label>
                    <span class="text-danger d-block" v-if="errors.f_name">
                      First Name is required
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-sm mb-3"
                      required
                      v-model="admin.f_name"
                    />
                  </div>
                  <div class="col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      class="form-control form-control-sm mb-3"
                      v-model="admin.l_name"
                    />
                  </div>
                </div>
                      <div class="row">

                        <div class="col-md-6">
                  <label>Phone</label>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    v-model="admin.phone"
                    @keypress="isNumber($event)"
                  />
                </div>
                <div class="col-md-6">
                  <label>Designation</label>
                  <input
                    type="text"
                    class="form-control form-control-sm mb-3"
                    v-model="admin.desigantion"
          
                  />
                  </div>

                        
                </div>
                <div class="form-group">
                  <label>Email <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.email">
                    Email is required
                  </span>
                  <input
                    type="email"
                    class="form-control form-control-sm mb-3"
                    required
                    v-model="admin.email"
                  />
                </div>
                <div class="form-group">
                  <label>Password <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.password">
                    Password is required
                  </span>
                  <input
                    type="password"
                    class="form-control form-control-sm mb-3"
                    required
                    v-model="admin.password"
                  />
                </div>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <button type="submit" class="btn btn-sm btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { toRaw } from "vue";
  import isNumber from "@/helpers/helpers";
  
  export default {
    mixins: [isNumber],
    data() {
      return {
        admin: {
          f_name: null,
          l_name: null,
          phone: null,
          email: null,
          password: null,
          desigantion:null,
          role:'ristricted access',
          createdBy: localStorage.getItem("uid"),
        },
        createdBy: localStorage.getItem("uid"),
        errors: [],
        loading: false,
      };
    },
    methods: {
      submit() {
        this.loading = true;
        this.errors = [];
        const data = toRaw(this.admin);
        this.$store
          .dispatch("admins/store", { data })
          .then(() => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
            this.admin.role='ristricted access';
            this.admin.createdBy=this.createdBy;
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
          });
      },
    },
  };
  </script>
  