<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header card-no-border">
                        <h5 class="card-title text-center">Comments</h5>
                    </div>
                    <div class="card-body">

                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-8">
                                    <div class="card">
                                        <div class="card-body">
                                            <!-- Chat Messages -->
                                            <div v-for="comment in items.comments" class="media mt-3">
                                                <img src="@/assets/images/logo/avator.png"
                                                    class="mr-3 rounded-circle me-2" alt="User Avatar">

                                                <div class="media-body">
                                                    <h5 class="mt-0">{{ comment.admin.f_name }}</h5>
                                                    <p>{{ comment.comment }}</p>
                                                </div>

                                                <hr>
                                            </div>



                                            <!-- End Chat Messages -->

                                            <!-- New Message Form -->
                                            <form class="mt-4" @submit.prevent="submitComment">
                                                <div class="form-row">
                                                    <div class="col">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter your message" v-model="comment">
                                                    </div>
                                                    <div class="col-auto mt-3">
                                                        <button type="submit" class="btn btn-primary">Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <!-- End New Message Form -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            searchValue: "",
            enforcementId: "",
            comment: "",
            viewable: true,
            columns: [
                {
                    text: "Complainant Email",
                    value: "user",
                    secondValue: "email",
                },
                {
                    text: "Title",
                    value: "title",
                },
                {
                    text: "Date",
                    value: "created_at",
                },
                {
                    text: "Status",
                    value: "complaintStatus",
                },
                {
                    text: "Reference No.",
                    value: "referenceNo",
                },
            ],
            items: [],

            module: "complaint",
            loading: false,
        };
    },
    mounted() {
        this.enforcementId = parseInt(this.$route.params.id);
        this.fetch();
        setInterval(() => {
            this.getComments();
        }, 1000);
    },
    methods: {
        fetch() {
            this.loading = true;
            const id = this.enforcementId;
            this.$store
                .dispatch("complaint/eOComments", { id })
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        getComments() {
            const id = this.enforcementId;
            this.$store
                .dispatch("complaint/eOComments", { id })
                .then((response) => {
                    this.items.comments = response.data.comments;
                })
                .catch((e) => {
                    console.log(e.error);
                });
        },
        submitComment() {
            this.loading = true;
            this.errors = [];
            const data = {
                'comment': this.comment,
                'enforcement_id': this.enforcementId
            };
            this.$store
                .dispatch("complaint/storeComment", { data })
                .then(() => {
                    this.loading = false;
                    this.$toast.show("Successfuly added.", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.errors = [];
                    this.comment = "";
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        }
    },
};
</script>