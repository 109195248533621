<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/app-users" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add User</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addUser">
                            <div class="form-group">
                                <label>Full Name <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.fname">
                                    {{ errors.fname && errors.fname.length > 0 ? errors.fname[0] : '' }}
                                </span>
                                <span class="text-danger d-block" v-if="errors.f_name">
                                    First Name is required
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" required
                                    v-model="admin.f_name" />
                            </div>
                            <div class="form-group">
                                <label>Phone <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.phone">
                                    {{ errors.phone && errors.phone.length > 0 ? errors.phone[0] : '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.phone"
                                    @keypress="isNumber($event)"   maxlength="11" minlength="11"  required/>
                            </div>
                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.cnic">
                                    {{ errors.cnic && errors.cnic.length > 0 ? errors.cnic[0] : '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" maxlength="13" minlength="13"  required />
                            </div>
                            <div class="form-group">
                                <label>Address</label>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.address" />
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>District <span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" @change="districtChange"
                                        v-model="admin.district_id" required>
                                        <option value="">Select...</option>
                                        <option v-for="district in districts" :value="district.id">
                                            {{ district.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Tehsil <span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" @change="tehsilChange" v-model="admin.tehsil_id"
                                        required>
                                        <option value="">Select...</option>
                                        <option v-for="tehsil in tehsils" :value="tehsil.id">
                                            {{ tehsil.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Area <span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" v-model="admin.area_id" required>
                                        <option value="">Select...</option>
                                        <option v-for="area in areas" :value="area.id">
                                            {{ area.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label>Qualification</label>
                                    <select class="custom-select mb-3" v-model="admin.qualification_id">
                                        <option value="">Select...</option>
                                        <option v-for="qualification in qualifications" :value="qualification.id">
                                            {{ qualification.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Date of birth</label>
                                    <input type="date" class="form-control form-control-sm mb-3"
                                        v-model="admin.date_of_birth" />
                                </div>
                                <div class="col-md-6">
                                    <label>Profession</label>
                                    <input type="text" class="form-control form-control-sm mb-3"
                                        v-model="admin.profession" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Gender</label>
                                    <div class="d-flex">
                                        <div class="form-check me-3">
                                            <input type="radio" class="form-check-input" id="genderMale" value="Male"
                                                v-model="admin.gender">
                                            <label class="form-check-label" for="genderMale">Male</label>
                                        </div>
                                        <div class="form-check me-3">
                                            <input type="radio" class="form-check-input" id="genderFemale"
                                                value="Female" v-model="admin.gender">
                                            <label class="form-check-label" for="genderFemale">Female</label>
                                        </div>
                                        <div class="form-check">
                                            <input type="radio" class="form-check-input" id="genderOthers"
                                                value="Others" v-model="admin.gender">
                                            <label class="form-check-label" for="genderOthers">Others</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>
                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" />
                            </div>
                            <div class="form-group">
                                <label>Password <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.password">
                                    {{ errors.password && errors.password.length > 0 ? errors.password[0] : '' }}
                                </span>
                                <span class="text-danger d-block" v-if="errors.password">
                                    Password is required
                                </span>
                                <input type="password" class="form-control form-control-sm mb-3" required
                                    v-model="admin.password" />
                            </div>

                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
    mixins: [isNumber],
    data() {
        return {
            admin: {
                f_name: null,
                l_name: null,
                phone: null,
                email: null,
                password: null,
                officer_type: "",
                cnic: null,
                district_id: "",
                address: "",
                qualification: "",
                date_of_birth: "",
                district: "",
                city: "",
                gender: "",
                qualification_id: "",
                tehsil_id: "",
                area_id: "",
                createdBy: localStorage.getItem("uid"),
            },
            districts: [],
            qualifications: [],
            tehsils: [],
            areas: [],
            errors: [],
            loading: false,
        };
    },
    mounted() {
        this.fetchQualifications();
        this.fetchDistricts();
    },
    methods: {
        submit() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.admin);
            this.$store
                .dispatch("users/store", { data })
                .then(() => {
                    this.loading = false;
                    this.$toast.show("Successfuly added.", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
                    this.errors = [];
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchQualifications() {
            this.loading = true;
            this.$store
                .dispatch("qualification/getAll")
                .then((response) => {
                    this.qualifications = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        districtChange() {
            this.admin.tehsil_id = "";
            this.admin.area = "";
            const id = this.admin.district_id;
            this.loading = true;
            this.$store
                .dispatch("districts/getAllTehsilByDistrict", { id })
                .then((response) => {
                    this.tehsils = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        tehsilChange() {
            this.admin.area_id = "";
            const id = this.admin.tehsil_id;
            this.loading = true;
            this.$store
                .dispatch("tehsil/getAllAreaByTehsil", { id })
                .then((response) => {
                    this.areas = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        }

    },
};
</script>