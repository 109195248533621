<template>
  <div class="col-md-12 mt-4">
    <div class="card">
      <div class="card-header card-no-border mb-5">
        <p class="text-black p-0 m-0" style="font-size: 21px;">Complaints Overview</p>
      </div>
      <div class="card-body pt-0">
        <div class="row m-0 overall-card">
          <div class="col-xl-9 col-md-12 col-sm-7 p-0">
            <div class="chart-right">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card-body p-0">
                    <div class="current-sale-container" v-if="isDataLoaded">
                      <apexchart height="305" type="bar" :options="options3" :series="series3"></apexchart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-12 col-sm-5 p-0">
            <div class="row g-sm-4 g-2">

              <div class="col-xl-12 col-md-4">
                <div class="light-card balance-card widget-hover">
                  <div class="svg-box">
                    <i :class="'fa fa-envelope-o'" class="text-white"></i>
                  </div>
                  <div>
                    <span class="f-light">Total Complaints</span>
                    <h6 class="mt-1 mb-0">{{ data?.total_complaints }}</h6>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-md-4">
                <div class="light-card balance-card widget-hover">
                  <div class="svg-box">
                    <i :class="'fa fa-mixcloud'" class="text-white"></i>
                  </div>
                  <div>
                    <span class="f-light">Solved Problems</span>
                    <h6 class="mt-1 mb-0">{{ data?.solved }}</h6>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-md-4">
                <div class="light-card balance-card widget-hover">
                  <div class="svg-box">
                    <i :class="'fa fa-circle-o-notch'" class="text-white"></i>
                  </div>
                  <div>
                    <span class="f-light">Remaining</span>
                    <h6 class="mt-1 mb-0">{{ data?.remaining }}</h6>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown1 from "../base/dropdown/DropDown1.vue";
import { overallbalance } from "../../../data/dashboard/default";
import { apexDashboard } from "../../../data/comon";

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.isDataLoaded = true;
        }
      },
      immediate: true
    },
    'data.chartData': {
      handler(newVal) {
        if (newVal) {
          this.options3.xaxis.categories = newVal.months;
          this.series3[0].data = newVal.complaintsCounts;

        }
      },
      deep: true
    }
  },
  components: {
    DropDown1,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      overallbalance: overallbalance,
      isDataLoaded: false,
      options3: {
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 8,
            left: 0,
            blur: 10,
            color: "#0468B1",
            opacity: 0.1,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25px",
            borderRadius: 0,
          },
        },
        grid: {
          show: true,
          borderColor: "var(--chart-border)",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          dashArray: 0,
          lineCap: "butt",
          colors: "#fff",
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 1,
            },
          },
        },
        fill: {
          colors: ["#0468B1"],
        },
        colors: "#7366ff",
        yaxis: {
          tickAmount: 3,
          labels: {
            show: true,
            style: {
              fontFamily: "Rubik, sans-serif",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Ocb",
            "Nov",
            "Dec",

          ],
          labels: {
            style: {
              fontFamily: "Rubik, sans-serif",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: "darken",
              value: 1,
            },
          },
        },
        responsive: [
          {
            breakpoint: 1661,
            options: {
              chart: {
                height: 290,
              },
            },
          },
          {
            breakpoint: 767,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: "35px",
                },
              },
              yaxis: {
                labels: {
                  show: false,
                },
              },
            },
          },
          {
            breakpoint: 481,
            options: {
              chart: {
                height: 200,
              },
            },
          },
          {
            breakpoint: 420,
            options: {
              chart: {
                height: 170,
              },
              plotOptions: {
                bar: {
                  columnWidth: "40px",
                },
              },
            },
          },
        ],
      },
      series3: [
        {
          name: "Complaints",
          data: [
            200, 200, 350, 400, 200, 250, 250, 350, 350, 500, 500, 700
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.card .card-header {
  background-color: #dfdfdf !important;
  color: #fff;
}

h6 {
  color: #ff432a !important;
}

.f-light {
  color: #000 !important;
}

.balance-card .svg-box {
  background-color: #0468B1 !important;
}
</style>
