<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">Ristricted Access </h5>
                            </div>
                            <div class="col-6 text-end">
                                <router-link to="/ristricted-access/add" class="btn btn-sm"
                                    v-if="permissions && permissions.includes('ra-Add')"
                                    style="background-color: rgb(255, 67, 42); color:white;">Add Ristricted
                                    Access</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" @blockUser="blockUser" :pagination="pagination"
                            :searchBar="searchBar" :isAdminBlock="isAdminBlock" :items="items" :editable="isedit"
                            @deleted="fetch"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            searchValue: "",
            columns: [
                {
                    text: "Name",
                    value: "f_name",
                },
                {
                    text: "Email",
                    value: "email",
                },
            ],
            items: [],
            module: "ristricted-access",
            loading: false,
            changePassword: true,
            isedit: false, 
            isAdminBlock: false,
            searchBar: true,
            pagination: true,
            permissions: [],
        };
    },
    mounted() {
        this.fetch();
        this.permissions = localStorage.getItem("permissions");
        this.isedit = (this.permissions && this.permissions.includes("ra-Edit")) ? true : false;
        this.isAdminBlock = (this.permissions && this.permissions.includes("ra-Block")) ? true : false;
    },
    methods: {
        fetch() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAllRestrictedAdmins", { id })
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        blockUser(blockData) {
            this.loading = true;
            const id = blockData.id;
            const data = {
                reason: blockData.reason,
                model_type: 'App\\Models\\Admin\\Admin',
                type: blockData.type
            };
            this.$store
                .dispatch("admins/blockAdmin", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.fetch();
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },
};
</script>