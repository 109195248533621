<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">Search And Report</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <label>Select Data Type <span class="text-danger">*</span></label>
                                    <select class="custom-select mb-3" v-model="filter.type" required>
                                        <option value="">Select...</option>
                                        <option value="dynamicform">Dynamic Forms</option>
                                        <option value="users">Users</option>
                                        <option value="complaints">Complaints</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="filter.type == 'dynamicform'">
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Select Form <span class="text-danger">*</span></label>
                                        <select class="custom-select mb-3" v-model="filter.formId" required>
                                            <option value="">Select...</option>
                                            <option v-for="form in forms" :key="form.id" :value="form.id">{{ form.title
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Start Date </label>
                                        <VueDatePicker v-model="filter.startDate" class="custom-select mb-3" type="date"
                                            format="yyyy-MM-dd">
                                        </VueDatePicker>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>End Date </label>
                                        <VueDatePicker v-model="filter.endDate" class="custom-select mb-3" type="date"
                                            format="yyyy-MM-dd">
                                        </VueDatePicker>
                                    </div>
                                </div>
                            </div>
                            <div v-if="filter.type == 'users' || filter.type == 'complaints'">

                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>Start Date </label>
                                        <VueDatePicker v-model="filter.startDate" class="custom-select mb-3" type="date"
                                            format="yyyy-MM-dd">
                                        </VueDatePicker>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-6">
                                        <label>End Date </label>
                                        <VueDatePicker v-model="filter.endDate" class="custom-select mb-3" type="date"
                                            format="yyyy-MM-dd">
                                        </VueDatePicker>
                                    </div>

                                </div>



                                <div v-if="role == 'DC' || role == 'super-admin' || role == 'Department-Head'">
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>District </label>
                                            <select class="custom-select mb-3" @change="districtChange"
                                                v-model="filter.district_id">
                                                <option value="">Select...</option>
                                                <option v-for="district in districts" :value="district.id">
                                                    {{ district.name }}
                                                </option>
                                            </select>
                                        </div>



                                    </div>


                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Tehsil </label>
                                            <select class="custom-select mb-3" @change="tehsilChange"
                                                v-model="filter.tehsil_id">
                                                <option value="">Select...</option>
                                                <option v-for="tehsil in tehsils" :value="tehsil.id">
                                                    {{ tehsil.name }}
                                                </option>
                                            </select>
                                        </div>




                                    </div>



                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Area </label>
                                            <select class="custom-select mb-3" v-model="filter.area_id">
                                                <option value="">Select...</option>
                                                <option v-for="area in areas" :value="area.id">
                                                    {{ area.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>












                            </div>





                            <button type="submit" class="btn btn-sm mt-3" style="color:white;">Download</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from 'vue3-datepicker';
import 'vue3-datepicker/dist/vue3-datepicker.css';
import axios from 'axios';
import areas from '@/store/modules/areas';
import roles from '@/store/modules/roles';
import reporting from '@/store/modules/reporting';

export default {
    components: {
        VueDatePicker
    },
    data() {
        return {
            filter: {
                type: "",
                startDate: null,
                endDate: null,
                formId: "",
                district_id: "",
                tehsil_id: "",
                area_id: "",
            },
            forms: [],
            districts: [],
            tehsils: [],
            areas: [],
            role: localStorage.getItem('role'),
            loading: false
        };
    },
    created() {
        this.fetchForms();
        this.fetchDistricts();
    },
    methods: {
        fetchForms() {
            this.loading = true;
            this.$store.dispatch("form/getAll").then((response) => {
                this.forms = response.data;
                this.loading = false;
            }).catch((e) => {
                this.loading = false;
                console.log(e.error);
            });
        },
        async submit() {
            try {
                this.loading = true;
                const baseURL = `${process.env.VUE_APP_BASE_URL}/reporting`;
                const response = await axios.post(baseURL, this.filter, { responseType: 'blob' });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log("Error downloading file:", error);
                this.$toast.show(error.message, {
                    theme: "outline",
                    position: "top",
                    type: "error",
                    duration: 10000,
                });
            }
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll")
                .then((response) => {
                    this.districts = response.data;
                    if (this.role == 'DC' || this.role == 'Department-Head') {
                        this.filter.district_id = this.districts[0].id;
                        this.districtChange();
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

        districtChange() {
            this.filter.tehsil_id = "";
            this.filter.area_id = "";
            const id = this.filter.district_id;
            this.loading = true;
            this.$store
                .dispatch("districts/getAllTehsilByDistrict", { id })
                .then((response) => {
                    this.tehsils = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        tehsilChange() {
            this.filter.area_id = "";
            const id = this.filter.tehsil_id;
            this.loading = true;
            this.$store
                .dispatch("tehsil/getAllAreaByTehsil", { id })
                .then((response) => {
                    this.areas = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        }
    },

};
</script>
