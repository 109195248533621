import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/feedback${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },  
    getAllanonymousFeedback({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/anonymous-feedback${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },  
    getAllSupport({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/support${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },  
    getSingle({ dispatch, commit },{id}) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/feedback/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },  
  },
};
