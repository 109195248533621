<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/faq" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit District</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label>Question <span class="text-danger">*</span></label>
                            <textarea v-model="faqs.question" class="form-control form-control-sm mb-3" rows="6"
                                required></textarea>
                            <label>Answer <span class="text-danger">*</span></label>
                            <textarea v-model="faqs.answer" class="form-control form-control-sm mb-3" rows="6"
                                required></textarea>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            faqs: {

            },
         loading:false,
        };
    },
    mounted() {
        this.faqId = parseInt(this.$route.params.id);
        this.fetch(this.faqId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("faqs/getSingle", { id })
                .then((response) => {
                    this.faqs = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.faqs);
            const id = this.faqId;
            this.$store
                .dispatch("faqs/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },


    },
};
</script>