<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/feedback" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Feedback</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">

                            <label>Name</label>
                            <input type="text" class="form-control form-control-sm mb-3"
                                :value="feedback?.user_profile?.name" />

                            <label>Phone</label>
                            <input type="text" class="form-control form-control-sm mb-3"
                                :value="feedback?.user?.phone" />

                            <label>Cnic</label>
                            <input type="text" class="form-control form-control-sm mb-3"
                                :value="feedback?.user?.cnic" />

                            <label>Email</label>
                            <input type="text" class="form-control form-control-sm mb-3"
                                :value="feedback?.user?.email" />

                            <label>Type</label>
                            <input type="text" class="form-control form-control-sm mb-3" :value="feedback?.type" />

                            <label>Feedback</label>
                            <textarea type="text" class="form-control form-control-sm mb-3" rows="5" :value="feedback?.feedback" ></textarea>

                            <div v-if="feedback?.media?.image_url">
                               
                                <button type="button" @click="openAttachment" class="btn btn-sm btn-primary">
                                    Attachment
                                </button>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {

            feedback: [],
            areaId: null,
            loading: false,
        };
    },
    mounted() {
        this.feedbackId = this.$route.params.id;
        this.fetch(this.feedbackId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("feedback/getSingle", { id })
                .then((response) => {
                    this.feedback = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        openAttachment() {
            const url = this.feedback.media.image_url;
            if (url) {
                window.open(url, '_blank');
            }
        }
    },

};
</script>