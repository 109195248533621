<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/district-officers" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addUser">
              <div class="form-group row">
                <div class="col-md-6">
                  <label>First Name <span class="text-danger">*</span></label>
                  <span class="text-danger d-block" v-if="errors.fname">
                    {{ errors.fname && errors.fname.length > 0 ? errors.fname[0] : '' }}
                  </span>
                  <span class="text-danger d-block" v-if="errors.f_name">
                    First Name is required
                  </span>
                  <input type="text" class="form-control form-control-sm mb-3" required v-model="admin.f_name" />
                </div>
                <div class="col-md-6">
                  <label>Last Name</label>
                  <input type="text" class="form-control form-control-sm mb-3" v-model="admin.l_name" />
                </div>
              </div>
              <div class="form-group">
                <label>Phone</label>
                <span class="text-danger d-block" v-if="errors.phone">
                  {{ errors.phone && errors.phone.length > 0 ? errors.phone[0] : '' }}
                </span>
                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.phone"
                  @keypress="isNumber($event)" maxlength="11" minlength="11" />
              </div>
              <div class="form-group">
                <label>Cnic <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.cnic">
                  {{ errors.cnic && errors.cnic.length > 0 ? errors.cnic[0] : '' }}
                </span>
                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                  @keypress="isNumber($event)" maxlength="13" minlength="13" />
              </div>
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.email">
                  {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                </span>
                <span class="text-danger d-block" v-if="errors.email">
                  Email is required
                </span>
                <input type="email" class="form-control form-control-sm mb-3" required v-model="admin.email"
                  autocomplete="off" />
              </div>


              <div class="form-group row mt-3">
                <div class="col-md-3">
                  <img :src="previewProfilePic" style="
                      width: 120px;
                      height: 120px;
                      border-radius: 50%;
                      border: 1px solid #ddd;
                    " />
                </div>
                <div class="col-md-8 mt-3">
                  <label>Upload Profile Picture</label>
                  <div class="position-relative">
                    <input type="file" ref="profilePic" class="position-relative"
                      accept="image/png, image/jpg, image/jpeg" @change="profilePicSelected"
                      style="opacity: 0; z-index: 100" />
                    <button type="button" class="btn btn-primary primary-bg-color position-absolute" style="left: 0">
                      Browse...
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group mt-3">
                <label>District <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.district_id">
                  {{ errors.district_id && errors.district_id.length > 0 ? errors.district_id[0] : ''
                  }}
                </span>
                <select class="custom-select mb-3" v-model="admin.district_id" disabled>
                  <option value="">Select...</option>
                  <option v-for="district in districts" :value="district.id">
                    {{ district.name }}
                  </option>
                </select>

              </div>
              <div class="form-group">
                <label>Designation <span class="text-danger">*</span></label>
                <span class="text-danger d-block" v-if="errors.officer_type">
                  {{ errors.officer_type && errors.officer_type.length > 0 ? errors.officer_type[0] :
                    ''
                  }}
                </span>
                <select class="custom-select mb-3" v-model="admin.officer_type" disabled>
                  <option value="">Select...</option>
                  <option v-if="role !== 'DC'" value="DC">
                    DC
                  </option>
                  <option value="inquiry-officer">
                    IO
                  </option>
                  <option v-if="role == 'DC'" value="EO">
                    Enforcement officer
                  </option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
  mixins: [isNumber],
  data() {
    return {
      admin: {
        f_name: null,
        l_name: null,
        phone: null,
        email: null,
        password: null,
        officer_type: "",
        cnic: null,
        district_id: "",
        role: "",
        prevRole: "",
        profileImage:"",
      },
      roles: [],
      errors: [],
      districts: [],
      userId: null,
      loading: false,
      previewProfilePic: "",
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.fetch(this.userId);
    this.fetchDistricts();
  },
  methods: {
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("officers/getSingle", { id })
        .then((response) => {
          this.admin = response.data;
          const { cnic, district_id, officer_type } = this.admin.officer;
          Object.assign(this.admin, {
            cnic,
            district_id,
            officer_type,
            role: officer_type,
            prevRole: officer_type
          });
          this.previewProfilePic=this.admin.profile.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.admin);
      const id = this.admin.id;
      this.$store
        .dispatch("officers/update", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly updated.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.errors = [];
        })
        .catch((e) => {
          this.errors = e.error;
          this.loading = false;
        });
    },
    fetchDistricts() {
      this.loading = true;
      this.$store
        .dispatch("districts/getAll")
        .then((response) => {
          this.districts = response.data;
          console.log("districts", this.districts);
          if (this.districts.length === 1 && this.role == "DC") {
            this.admin.district_id = this.districts[0].id;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    profilePicSelected(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.admin.profileImage = e.target.result;
          this.previewProfilePic = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>