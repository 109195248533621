<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header card-no-border">
            <h5 class="card-title">Appeal Complaints</h5>
          </div>
          <div class="card-body">
            <!--              
              <button type="button" class="btn btn-primary btn-sm mb-2">Re-Open Requests(7)</button>
   -->
            <data-table :columns="columns" :module="module" :items="items" :isChangePassword="changePassword"
              :viewable="viewable" :pagination="pagination" :searchBar="searchBar" @deleted="fetch" @search="fetch"
              @clear="fetch" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      viewable: true,
      columns: [
        {
          text: "Complainant Email",
          value: "user_profile",
          secondValue: "name",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Date",
          value: "complaint_date",
        },
        {
          text: "Status",
          value: "complaintStatus",
        },
        {
          text: "Reference No.",
          value: "referenceNo",
        },
      ],
      items: [

      ],
      module: "complaint",
      loading: false,
      searchBar: true,
      pagination: true
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("complaint/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.items.data = this.items.data.filter(item => item.complaintStatus == "Appealed");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}&type=${this.complaintType}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.items.data = this.items.data.filter(item => item.complaintStatus == "Appealed");
        this.loading = false;
      });
    },
  },
};
</script>