<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/tehsil" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Tehsil</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="tehsil.name" />
                            <div class="form-group">
                                <label>District <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm mb-3" v-model="tehsil.district_id" required>
                                    <option value="">Select...</option>
                                    <option v-for="district in districts" :value="district.id">
                                        {{ district.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            tehsil: {
                name: "",
                district_id: "",
            },
            districts: [],
            departmentId: null,
            loading: false,
        };
    },
    mounted() {
        this.tehsilId = this.$route.params.id;
        this.fetch(this.tehsilId);
        this.fetchDistricts();
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("tehsil/getSingle", { id })
                .then((response) => {
                    this.tehsil = response.data;
                    console.log("tehsil",this.tehsil);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.tehsil);
            const id = this.tehsilId;
            this.$store
                .dispatch("tehsil/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },

};
</script>
  