<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="@/assets/images/logo/logo-green.png" alt="looginpage" />
                  </a>
                </div>
                <div class="login-main">
                  <form class="theme-form" @submit.prevent="submit">
                    <h4>Verify Email</h4>
                    <div class="alert alert-success" v-if="msg">
                      <span class="d-block">{{ msg }}</span>
                    </div>
                    <div class="alert alert-danger" v-if="errors.email">
                      <span class="d-block">{{ errors.email[0] }}</span>
                    </div>
                    <div class="form-group mt-3">
                      <div class="d-flex align-items-center mb-3"
                        style="border-bottom: 2px solid #0468B1; padding-bottom: 5px;">
                        <i class="fa fa-envelope" style="margin-right: 10px;"></i>
                        <input class="form-control form-control-sm border-0" type="email" placeholder="email@test.com"
                          v-model="email" required autofocus
                          style="margin-left: 10px; border: none; outline: none; box-shadow: none;" />
                      </div>

                    </div>
                    <div class="form-group mb-0">
                      <button type="submit" class="btn btn-sm btn-primary btn-block">
                        Verify
                      </button>
                    </div>
                    <p class="mt-4 mb-0">
                      Already have an account?
                      <router-link to="/auth/login"> Login </router-link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { toRaw } from "vue";

export default {
  data() {
    return {
      active: true,
      email: null,
      loading: false,
      errors: [],
      msg: null,
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    submit() {
      this.loading = true;
      this.errors = [];
      const data = { email: toRaw(this.email) };
      this.$store
        .dispatch("auth/verifyEmail", { data })
        .then((response) => {
          this.loading = false;
          this.msg = response.data;
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>
