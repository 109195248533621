<template>
  <div class="row">
    <div class="col-md-6 appointment-sec box-col-6">
      <div class="appointment">
        <div class="card">
          <div class="card-header card-no-border mb-4">
            <div class="header-top">
              <p class="text-black m-0" style="font-size: 18px;">Recent Complaints</p>
              <!-- <div class="card-header-right-icon">
                <DropDown2 />
              </div> -->
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="appointment-table table-responsive">
              <table class="table table-bordernone">
                <tbody>
                  <tr v-if="data?.recent_complaints?.length === 0">
                    <td colspan="3" class="text-center">No Complaints</td>
                  </tr>
                  <tr v-if="data.role != 'admin'" v-for="item in data.recent_complaints" :key="item">
                    <td>
                      <img class="img-fluid img-40 rounded-circle" src="@/assets/images/dashboard/user/1.jpg"
                        alt="user" />
                    </td>
                    <td class="img-content-box">
                      <p class="d-block f-w-50">{{
                        item?.complaint?.title
                      }}</p><span class="f-light">{{ item?.formatted_created_at }}</span>
                    </td>
                    <td class="text-end">
                      <p class="m-0 font-default">{{ item?.complaint?.user?.phone }}</p>
                    </td>
                  </tr>

                  <tr v-else v-for="item in data.recent_complaints">
                    <td>
                      <img class="img-fluid img-40 rounded-circle" src="@/assets/images/dashboard/user/1.jpg"
                        alt="user" />
                    </td>
                    <td class="img-content-box">
                      <p class="d-block f-w-50">{{
                        item?.title
                      }}</p><span class="f-light">{{ item?.formatted_created_at }}</span>
                    </td>
                    <td class="text-end">
                      <p class="m-0 font-default">{{ item?.user?.phone }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-md-6 appointment-sec box-col-6">
      <div class="appointment">
        <div class="card">
          <div class="card-header card-no-border mb-2">
            <div class="header-top">
              <p class="text-black m-0" style="font-size: 18px;">Calender</p>
            </div>
          </div>
          <div class="card-body">
            <div class="default-datepicker">
              <Datepicker1
                class="datepicker-here mb-3"
                inline
                autoApply
                v-model="date1"
                range
                data-language="en"
              ></Datepicker1>
            </div>
          </div>
        </div>
      </div>
    </div>

   

  </div>
</template>

<script>
import DropDown2 from "../base/dropdown/DropDown2.vue";
import { resentSales } from "../../../data/dashboard/default";
import { apexDashboard } from "../../../data/comon";
import Datepicker1 from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    date1(newDate) {
      this.$emit("daterange",newDate);
    }
  },
  components: {
    DropDown2,
    Datepicker1,
  },
  data() {
    return {
      apexDashboard: apexDashboard,
      resentSales: resentSales,
      date1:null
    };
  },
};
</script>
<style scoped>
.card .card-header {
  background-color: #dfdfdf !important;
}
</style>
