<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">SOP Modules</h5>
                            </div>
                            <div class="col-6 text-end">

                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
                            @sopUpdate="sopUpdate" :forms="forms" :items="items" @deleted="fetch" :isupdate="isupdate"
                            :deleteable="isdelete"   @search="fetch" @clear="fetch" @nextPage="nextPage"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import { toRaw } from "vue";
import axiosInstance from "@/helpers/axios";
export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [

            ],
            forms: [],
            columns: [
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Days",
                    value: "days",
                },
                {
                    text: "Form Attched",
                    value: "form",
                    secondValue: "title",
                },
            ],
            module: "sops",
            loading: false,
            isupdate: false,
            isdelete: false,
            searchBar: true,
            pagination: true,
            Permissions: [],
        };
    },
    created() {
        this.fetch();
        this.fetchForms();
        this.permissions = localStorage.getItem("permissions");
        this.isupdate = (this.permissions && this.permissions.includes("sop-Update")) ? true : false;
    },
    methods: {
        fetch(search="") {
            this.loading = true;
            this.$store
                .dispatch("sops/getAll",{ search,paginate: true})
                .then((response) => {
                    this.items = response.data;
                    this.items.forEach((item) => {
                        if (!item.form) {
                            item.form = { id: null, title: 'N/A' };
                        }
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        fetchForms() {
            this.loading = true;
            this.$store
                .dispatch("form/getAll")
                .then((response) => {
                    this.forms = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
        sopUpdate(item) {
            this.loading = true;
            const data = toRaw(item);
            const id = item.id;
            this.$store
                .dispatch("sops/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        this.loading = false;
                        this.fetch();
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        downloadExcelFile() {
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const id = 14; // or dynamically set this value
            const downloadUrl = `${baseUrl}/download/submit-forms/${id}`;
            window.open(downloadUrl, '_blank');
        },
        nextPage(url) {
            this.loading = true;
            axiosInstance.get(`${url}&paginate=true`).then((response) => {
                this.items = response.data.data;
                this.loading = false;
            });
        },
    },
};
</script>