<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
        <div class="card">
            <div class="card-header pb-0">
                <h3>Area Wise Complaints</h3>
            </div>
            <div class="card-body">
                <div id="basic-bar">
                    <apexchart type="bar" height="350" ref="chart" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';

export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        series() {
            return [
                {
                    data: this.data.percentages || [] 
                }
            ];
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['Cantt Area', 'Khawazai', 'Chargo', 'Shaal', 'Kaga'],
                },
                colors: ['#7b1a23'] 
            }
        }
    },

    mounted() {
       
        this.$nextTick(() => {
            this.updateLabels(this.data.areas || []);
        });
    },

    watch: {
        data: {
            handler(newData) {
                this.updateLabels(newData.areas || []);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        updateLabels(areas) {
         
            if (areas.length) {
                this.chartOptions.labels = areas;
                this.$nextTick(() => {
                    if (this.$refs.chart) {
                        this.$refs.chart.updateOptions({
                            xaxis: {
                                categories: this.chartOptions.labels
                            }
                        });
                    }
                });
            }
        }
    },
}
</script>
