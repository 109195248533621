<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="card">
            <div class="card-header card-no-border">
              <h5 class="card-title">Appeal on Complaints (7)</h5>
            </div>
            <div class="card-body">
             
        
  
              <data-table
                :columns="columns"
                :module="module"
                :items="items"
                :isChangePassword="changePassword"
                :viewable="viewable"
                @deleted="fetch"
              ></data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DataTable from "@/components/dataTable.vue";
  
  export default {
    components: {
      DataTable,
    },
    data() {
      return {
        searchValue: "",
        viewable: true,
        columns: [
          {
            text: "Complainant Email",
            value: "user",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Date of Registration",
            value: "created_at",
          },
          {
            text: "Appeal Date",
            value: "appealDate",
          },
          {
            text: "Status",
            value: "complaintStatus",
          },
          {
            text: "Reference No.",
            value: "referenceNo",
          },
        ],
        items: [
  {
    user: "john@example.com",
    email: "john@example.com",
    title: "Issue with Product A",
    created_at: "2023-10-10",
    appealDate: "2023-11-11",
    complaintStatus: "Appeal",
    referenceNo: "REF12345"
  },
  {
    user: "jane@example.com",
    email: "jane@example.com",
    title: "Delay in Delivery",
    created_at: "2023-09-15",
    appealDate: "2023-11-16",
    complaintStatus: "Appeal",
    referenceNo: "REF54321"
  },
  {
    user: "alice@example.com",
    email: "alice@example.com",
    title: "Defective Item",
    created_at: "2023-08-20",
    appealDate: "2023-11-19",
    complaintStatus: "Appeal",
    referenceNo: "REF67890"
  },
  {
    user: "bob@example.com",
    email: "bob@example.com",
    title: "Missing Parts",
    created_at: "2023-07-25",
    appealDate: "2023-11-11",
    complaintStatus: "Appeal",
    referenceNo: "REF98765"
  },
  {
    user: "sara@example.com",
    email: "sara@example.com",
    title: "Wrong Color",
    created_at: "2023-06-30",
    appealDate: "2023-11-11",
    complaintStatus: "Appeal",
    referenceNo: "REF45678"
  },
  {
    user: "mike@example.com",
    email: "mike@example.com",
    title: "Late Shipment",
    created_at: "2023-05-05",
    appealDate: "2023-11-11",
    complaintStatus: "Appeal",
    referenceNo: "REF23456"
  },
  {
    user: "emily@example.com",
    email: "emily@example.com",
    title: "Damaged Packaging",
    created_at: "2023-04-10",
    appealDate: "2023-11-11",
    complaintStatus: "Appeal",
    referenceNo: "REF78901"
  }
],

        module: "complaint",
        loading: false,
      };
    },
    mounted() {
    //   this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        const id = localStorage.getItem("uid");
        this.$store
          .dispatch("complaint/getAll", { id })
          .then((response) => {
            this.items = response.data;
            this.loading = false;
          })
          .catch((e) => {
            console.log(e.error);
            this.loading = false;
          });
      },
    },
  };
  </script>
  