<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header text-center">
                        <h3>Carousel Images</h3>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="hero-tab" data-bs-toggle="tab"
                                    data-bs-target="#hero" type="button" role="tab" aria-controls="hero"
                                    aria-selected="true">Home</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="about-tab" data-bs-toggle="tab" data-bs-target="#about"
                                    type="button" role="tab" aria-controls="about" aria-selected="false">About</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="feature-tab" data-bs-toggle="tab" data-bs-target="#feature"
                                    type="button" role="tab" aria-controls="feature"
                                    aria-selected="false">Feature</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="privacy-tab" data-bs-toggle="tab" data-bs-target="#privacy"
                                    type="button" role="tab" aria-controls="privacy" aria-selected="false">How it
                                    works</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="hero" role="tabpanel" aria-labelledby="hero-tab">
                                <div class="container mt-4">
                                    <div class="row mb-4">
                                        <div class="col-md-12">
                                            <h3 class="text-center">Carousel Images</h3>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-3" v-for="(image, index) in curesolPerviews" :key="index">
                                            <input ref="curesolImages" type="file"
                                                @change="onFileChange($event, index, 'curesol')" accept="image/*"
                                                class="d-none" :id="'fileInputcuresol' + index">
                                            <label :for="'fileInputcuresol' + index">

                                                <img :src="image || '../../assets/images/home-section-1-pic.png'"
                                                    alt="Image Preview" class="img-fluid mt-2 mb-2 content-image">
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <h3 class="text-center">Divs</h3>
                                        <div class="col-md-3" v-for="(image, index) in sectionPerviews" :key="index">



                                            <input ref="sectionImages" type="file"
                                                @change="onFileChange($event, index, 'sections')" accept="image/*"
                                                class="d-none" :id="'fileInputsections' + index">
                                            <label :for="'fileInputsections' + index">
                                                <img :src="image.image" alt="Image Preview"
                                                    class="img-fluid mt-2 mb-2 content-image">
                                            </label>


                                            <div class="form-group">
                                                <label :for="'imageTitle' + index">Title</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.sections[index].title" :id="'imageTitle' + index">
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="about" role="tabpanel" aria-labelledby="about-tab">
                                <div class="container mt-4">
                                    <h3 class="text-center">About Us</h3>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec1_title" id="title" name="title">
                                            </div>

                                            <div class="form-group">
                                                <label for="description">Description</label>
                                                <div id="description">
                                                    <QuillEditor :editorHeight="'200px'"
                                                        v-model="data.aboutUs.sec1_description" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 text-center">
                                            <img src="../../assets/images/aboutus.png" alt="Image Preview"
                                                class="img-fluid mt-2 mb-2">
                                        </div>
                                    </div>




                                    <div class="row mt-3">

                                        <div class="row">





                                            <div class="col-md-6 text-center">
                                                <img src="../../assets/images/abouts-div.png" alt="Image Preview"
                                                    class="img-fluid mt-2 mb-2">
                                            </div>
                                            <div class="col-md-6 text-center">
                                                <img src="../../assets/images/abouts-div.png" alt="Image Preview"
                                                    class="img-fluid mt-2 mb-2">
                                            </div>




                                        </div>


                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev1_title" id="title" name="title">
                                                </div>

                                                <div class="form-group">
                                                    <label for="title">Date</label>
                                                    <input type="date" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev1_date" id="title" name="title">
                                                </div>

                                                <div class="form-group">
                                                    <label for="title">Description</label>
                                                    <textarea type="text" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev1_description" id="title" rows="5"
                                                        name="title" />
                                                </div>
                                            </div>



                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev2_title" id="title" name="title">
                                                </div>

                                                <div class="form-group">
                                                    <label for="title">Date</label>
                                                    <input type="date" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev2_date" id="title" name="title">
                                                </div>

                                                <div class="form-group">
                                                    <label for="title">Description</label>
                                                    <textarea type="text" class="form-control"
                                                        v-model="data.aboutUs.sec2_dev2_description" id="title" rows="5"
                                                        name="title" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row mt-2">

                                        <div class="col-md-12">
                                            <img src="../../assets/images/no_of_users.png" alt="Image Preview"
                                                class="img-fluid mt-2 mb-2 w-100">
                                        </div>


                                    </div>

                                    <div class="row mt-2">

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">No of Users</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_no_of_user_1" id="title" name="title">
                                            </div>
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_title_1" id="title" name="title">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">No of Users</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_no_of_user_2" id="title" name="title">
                                            </div>
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_title_2" id="title" name="title">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">No of Users</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_no_of_user_3" id="title" name="title">
                                            </div>
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_title_3" id="title" name="title">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="title">No of Users</label>
                                                <input type="text" class="form-control"
                                                    v-model="data.aboutUs.sec3_no_of_user_4" id="title" name="title">
                                            </div>
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control" id="title"
                                                    v-model="data.aboutUs.sec3_title_4" name="title">
                                            </div>
                                        </div>


                                    </div>







                                    <div class="row mt-2">
                                        <div class="col-md-12">
                                            <img src="../../assets/images/call_center.png" alt="Image Preview"
                                                class="img-fluid mt-2 mb-2 w-100">
                                        </div>

                                    </div>


                                    <div class="row mt-2">

                                        <div class="col-md-12">
                                            <div class="form-group">

                                                <QuillEditor class="w-100" v-model="data.aboutUs.sec4_call_center"
                                                    :editorHeight="'200px'" />

                                            </div>
                                        </div>

                                    </div>







                                </div>
                            </div>


                            <div class="tab-pane fade" id="feature" role="tabpanel" aria-labelledby="feature-tab">

                                <h3 class="text-center">Feature</h3>



                                <div class="container mt-4">

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="form-label">Title</label>
                                            <input type="text" v-model="data.feature.title" class="form-control">
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4 feature-card">

                                            <img v-if="data.featurePerview.div1_icon"
                                                :src="data.featurePerview.div1_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div1_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div1_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div1_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                        <div class="col-md-4 feature-card">

                                            <img v-if="data.featurePerview.div2_icon"
                                                :src="data.featurePerview.div2_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div2_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div2_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div2_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                        <div class="col-md-4 feature-card">
                                            <img v-if="data.featurePerview.div3_icon"
                                                :src="data.featurePerview.div3_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div3_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div3_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div3_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                        <div class="col-md-4 feature-card">
                                            <img v-if="data.featurePerview.div4_icon"
                                                :src="data.featurePerview.div4_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div4_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div4_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div4_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                        <div class="col-md-4 feature-card">
                                            <img v-if="data.featurePerview.div5_icon"
                                                :src="data.featurePerview.div5_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div5_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div5_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div5_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                        <div class="col-md-4 feature-card">
                                            <img v-if="data.featurePerview.div6_icon"
                                                :src="data.featurePerview.div6_icon" alt="Image Preview"
                                                class="mt-2 mb-2"
                                                style=" width: 70px ; height: 70px ; object-fit: cover; ">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    <b>Icon</b>
                                                </label>
                                                <input type="file" class="form-control"
                                                    @change="onFileChangeFeature($event, 'div6_icon')" accept="image/*">
                                            </div>


                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.feature.div6_title"
                                                    class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" v-model="data.feature.div6_description"
                                                    class="form-control" id="title" rows="3" name="title" />
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>



                            <div class="tab-pane fade" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">
                                <div class="container mt-4">
                                    <div class="row">
                                        <div class="col-md-8 offset-md-2">

                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.howVideo.title" class="form-control">
                                            </div>


                                            <video class="mt-2 mb-2 w-100" id="video-preview" controls
                                                :src="videoPreview" v-if="videoPreview" />

                                            <div class="form-group">
                                                <label for="videoInput">Choose Video</label>
                                                <input type="file" class="form-control" id="videoInput"
                                                    @change="onFileChangeVideo" accept="video/*">
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row mt-2">
                            <div class="col-md-3">
                                <button @click="submit" class="btn btn-sm">Save</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import { toRaw } from "vue";
import QuillEditor from "@/components/quilleditor.vue";

export default {
    components: {
        DataTable,
        QuillEditor
    },
    data() {
        return {
            items: [{ name: 'How to Register Complaint?' }],
            columns: [
                {
                    text: "Content",
                    value: "name",
                },
            ],
            loading: false,
            edit: true,
            delete: true,
            videoPreview: "",
            curesolPerviews: {
                0: "",
                1: "",
                2: "",
                3: "",
            },

            sectionPerviews: {

                0: {
                    image: "",

                },
                1: {
                    image: "",

                },
                2: {
                    image: "",

                },
                3: {
                    image: "",

                }
            },
            data: {
                images: {
                    0: "",
                    1: "",
                    2: "",
                    3: "",
                },
                sections: {

                    0: {
                        image: "",
                        title: "",
                    },
                    1: {
                        image: "",
                        title: "",
                    },
                    2: {
                        image: "",
                        title: "",
                    },
                    3: {
                        image: "",
                        title: "",
                    }
                },
                aboutUs: {
                },
                feature: {

                    title: "",


                },

                howVideo: {
                    title: "",
                    video: "",
                },

                featurePerview: {
                    "div1_icon": "",
                    "div2_icon": "",
                    "div3_icon": "",
                    "div4_icon": "",
                    "div5_icon": "",
                    "div6_icon": "",
                }
            },
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        onFileChange(event, imageNumber, type) {
            console.log("image no", type);
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (type == 'curesol') {
                        this.data.images[imageNumber] = e.target.result;
                        this.curesolPerviews[imageNumber] = e.target.result;
                    } else {
                        this.data.sections[imageNumber].image = e.target.result;
                        this.sectionPerviews[imageNumber].image = e.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }
        },
        onFileChangeFeature(event, div) {

            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {

                    this.data.feature[div] = e.target.result;
                    this.data.featurePerview[div] = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },


        onFileChangeVideo(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.videoPreview = e.target.result;
                    this.data.howVideo.video = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },


        submit() {
            console.log("data", this.data);

            this.loading = true;
            const data = toRaw(this.data);
            this.$store
                .dispatch("web/storeHeroSection", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });

        },
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("web/getAll")
                .then((response) => {
                    this.loading = false;
                    console.log("data response", response.data);

                    if (response.data.aboutUs) {
                        this.data.aboutUs = response.data.aboutUs;
                    }

                    if (response.data.feature) {
                        let feature = response.data.feature;
                        for (let key in feature) {

                            if (key.includes('_icon')) {
                                this.data.featurePerview[key] = feature[key];
                                delete feature[key];
                            }
                        }
                        this.data.feature = feature;
                    }

                    if (response.data.howitWorks) {
                        this.data.howVideo.title = response.data.howitWorks.title;
                        this.videoPreview = response.data.howitWorks.video.image_url;
                    }

                    if (response.data.heroSection.carousels) {
                        response.data.heroSection.carousels.forEach((cur, index) => {
                            this.curesolPerviews[index] = cur;
                        });
                    }

                    if (response.data.heroSection.divSections) {
                        response.data.heroSection.divSections.forEach((cur, index) => {
                            this.sectionPerviews[index].image = cur.media;
                            this.data.sections[index].title=cur.title;
                        });
                    }



                    console.log("data after", this.data);
                })
                .catch((e) => {
                    this.loading = false;
                });

        }
    },
};
</script>

<style scoped>
.content-image {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link.active {
    background-color: #0468B1;
    color: #fff;
}

.nav-tabs .nav-link {
    color: #0468B1;
}

.card-header {
    background-color: #0468B1;
    color: #fff;
}

.card-title {
    margin: 0;
}

.tab-content {
    margin-top: 20px;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    font-weight: 600;
}

.img-fluid {

    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#description .ql-container {
    height: 200px;
}

#description .ql-editor {
    min-height: 150px;
}

.ql-container.ql-snow {
    max-width: 100% !important;
}

.feature-card {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    width: 70px;
    height: 70px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>