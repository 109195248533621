<template>
    <div class="col-sm-12 col-xl-6 box-col-6">
        <div class="card">
            <div class="card-header pb-0">
                <h3>Complaints Overview</h3>
            </div>
            <div class="card-body">
                <div id="circlechart">
                    <apexchart height="320" type="radialBar" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';
var secondary = localStorage.getItem('secondary_color') || '#ba895d';
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        series() {
            return this.data.map(item => Number(item));
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                formatter: function (val) {
                                    return Math.round(val);
                                }
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: () => {
                                    return this.data[2];
                                }
                            }

                        }
                    }
                },
                labels: ['Pending Complaints', 'Resolved Complaints', 'Total Complaints'],
                colors: [
                    '#7b1a23',  
                    '#a86008',  
                    '#4b3b77',  
                ]
            }
        };
    }
}
</script>
