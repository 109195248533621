<template>
    <div class="col-sm-12 col-xl-12 box-col-12">
        <div class="card">
            <div class="card-header pb-0">
                <h3>Monthly Complaints</h3>
            </div>
            <div class="card-body">
                <div id="mixedchart">
                    <apexchart height="350" type="line" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';
var secondary = localStorage.getItem('secondary_color') || '#ba895d';
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },

    watch: {
        data: {
            handler(newVal) {
                if (newVal && newVal.months) {
                    this.chartOptions = {
                        ...this.chartOptions,
                        labels: newVal.months
                    };
                }
                if (newVal && newVal.complaintsCounts) {
                    this.series=[];
                    this.series.push({
                        name: 'Total Complaints',
                        type: 'column',
                        data: newVal.complaintsCounts
                    });
                }
            },
            deep: true
        }
    },


    data() {
        return {
            series: [{
                name: 'Total Complaints',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 90]
            }, {
                name: 'Resolved Complaints',
                type: 'area',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 56]
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: [0, 2, 5],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '50%'
                    }
                },
                // colors: ['#3A5794', '#A5C351', '#E14A84'],
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['jan', 'feb', 'march', 'april', 'may', 'june', 'july', 'aug', 'sep', 'ocb', 'nov', 'dec'],
                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'string'
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + " views";
                            }
                            return y;

                        }
                    }
                },
                legend: {
                    labels: {
                        useSeriesColors: true
                    },
                },
                colors: [primary, secondary, '#222222']
            }
        }
    }
}
</script>