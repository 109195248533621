<template>




    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4" @click="redirectWithComplaint('Total Complaints')">
          <div class="card l-bg-cherry row-card">
            <div class="card-statistic-3 p-4">
              <div class="mb-4">
                <h5 class="card-title mb-0">Total Complaints</h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.total_complaints }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        <div class="col-md-4" @click="redirectWithComplaint('Registered Complaints')">
          <div class="card l-bg-blue-dark row-card">
            <div class="card-statistic-3 p-4">
  
              <div class="mb-4">
                <h5 class="card-title mb-0">Registered Complaints </h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.stage_counts?.Registration }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
        <div class="col-md-4" @click="redirectWithComplaint('Investigation Complaints')">
          <div class="card l-bg-green-dark row-card">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"><i class="fas fa-ticket-alt"></i></div>
              <div class="mb-4">
                <h5 class="card-title mb-0">Investigation Complaints</h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.stage_counts?.Investigation }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-4" @click="redirectWithComplaint('Referral Complaints')">
          <div class="card l-bg-orange-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
              <div class="mb-4">
                <h5 class="card-title mb-0">Referral Complaints</h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.stage_counts?.Referral }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
  
        <div class="col-md-4" @click="redirectWithComplaint('Resolved Complaints')">
          <div class="card l-bg-purple-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
              <div class="mb-4">
                <h5 class="card-title mb-0">Resolved Complaints</h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.stage_counts?.Resolution }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
  
  
        <div class="col-md-4" @click="redirectWithComplaint('Pending Complaints')">
          <div class="card l-bg-red-dark">
            <div class="card-statistic-3 p-4">
              <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
              <div class="mb-4">
                <h5 class="card-title mb-0">Pending Complaints</h5>
              </div>
              <div class="row align-items-center mb-2 d-flex">
                <div class="col-8">
                  <h2 class="d-flex align-items-center mb-0">
                    {{ data?.counts?.stage_counts?.pending }}
                  </h2>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
  
  
  
  
      </div>
  
  
      <div class="row" v-if="data">
        <PieChart v-if="data.role == 'admin'" :data="data.distrcits_wise_complaints" />
        <DonoutChart v-if="data.role == 'admin'" :data="data.distrcits_wise_users" />
        <RadialChart :data="data.area_wise_complaints" />
        <BarChart :data="data.bar_chart" />
        <MixedChart :data="data.chartData" />
      </div>
  
    </div>
  
  </template>
  
  <script>
  
  
  import WelcomeCard from "./default/WelcomeCard.vue";
  import PurchaseSaleCard from "./default/PurchaseSaleCard.vue";
  import OrdersProfitCard from "./default/OrdersProfitCard.vue";
  import OverallBalanceCard from "./default/OverallBalanceCard.vue";
  import RecentOrdersCard from "./default/RecentOrdersCard.vue";
  import ActivitiesCard from "./default/ActivitiesCard.vue";
  import RecentSalesCard from "./default/RecentSalesCard.vue";
  import TimelineCard from "./default/TimelineCard.vue";
  import ProAccountCard from "./default/ProAccountCard.vue";
  import TotalUsersGrowthCard from "./default/TotalUsersGrowthCard.vue";
  import PaperNoteCard from "./default/PaperNoteCard.vue";
  import PieChart from "./default/PieChart";
  import DonoutChart from "./default/donoutChart";
  import MixedChart from "./default/mixedChart";
  import BarChart from "./default/bar";
  import RadialChart from "./default/radialChart";
  
  export default {
    components: {
      WelcomeCard,
      PurchaseSaleCard,
      OrdersProfitCard,
      OverallBalanceCard,
      RecentOrdersCard,
      ActivitiesCard,
      RecentSalesCard,
      TimelineCard,
      ProAccountCard,
      TotalUsersGrowthCard,
      PaperNoteCard,
      PieChart,
      DonoutChart,
      MixedChart,
      BarChart,
      RadialChart
    },
  
    data() {
      return {
        items: [],
        columns: [
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Type",
            value: "type",
          },
          {
            text: "Status",
            value: "status_text",
          },
          {
            text: "Date",
            value: "datecreate",
          },
        ],
        data: [],
        module: "news",
        permissions: null,
        isEdit: false,
        isDelete: false,
        loading: false,
        date: null,
  
      };
    },
  
  
  
    created() {
      this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        const daterange = this.date;
        this.$store
          .dispatch("dashboard/get", { daterange })
          .then((response) => {
            this.data = response.data;
            console.log("data", this.data);
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e.error);
          });
      },
      daterange(range) {
        this.date = range;
        this.fetch();
        console.log("range in parent", this.date);
      },
      redirectWithComplaint(type) {
        this.$router.push('/complaints');
      }
    },
  };
  
  
  
  </script>
  
  
  
  <style scoped>
  .card {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1), 0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12), 0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  }
  
  .l-bg-cherry {
    background: linear-gradient(to right, #493240, #f09) !important;
    color: #fff;
  }
  
  .l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #4286f4) !important;
    color: #fff;
  }
  
  .l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
  }
  
  .l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #ffba56) !important;
    color: #fff;
  }
  
  .l-bg-purple-dark {
    background: linear-gradient(to right, #4b3b77, #a14bfe) !important;
    color: #fff;
  }
  
  .l-bg-red-dark {
    background: linear-gradient(to right, #7b1a23, #ff6b6b) !important;
    color: #fff;
  }
  
  .card .card-statistic-3 .card-icon-large .fas,
  .card .card-statistic-3 .card-icon-large .far,
  .card .card-statistic-3 .card-icon-large .fab,
  .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
  }
  
  .card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
  }
  
  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }
  
  .l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
  }
  
  .l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
  }
  
  .l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
  }
  
  .row-card {
    margin-top: 1.5rem !important;
  }
  
  
  
  </style>