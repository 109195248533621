<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">Department Units</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/department-unit/add" v-if="permissions && permissions.includes('departmentUnit-Add')"
                  class="btn btn-sm btn-primary mb-2 float-end">Add Department
                  Unit</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :pagination="pagination" @blockUser="blockUser" :searchBar="searchBar"
              :module="module" :editable="editable" :isAdminBlock="isAdminBlock" :items="items"
              :isChangePassword="changePassword" @deleted="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Unit Name",
          value: "unit_name",
        },
        {
          text: "Name",
          value: "admin",
          secondValue: "f_name",
        },
        {
          text: "Email",
          value: "admin",
          secondValue: "email",
        },
        {
          text: "Department",
          value: "department",
          secondValue: "name",
        },
      ],
      items: [],
      module: "departmentunit",
      loading: false,
      isAdminBlock: true,
      editable: true,
      searchBar: true,
      pagination: true,
      permissions: [],

    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.editable = (this.permissions && this.permissions.includes("departmentUnit-Edit")) ? true : false;
    this.isAdminBlock = (this.permissions && this.permissions.includes("departmentUnit-Block")) ? true : false;
  },
  methods: {
    fetch() {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("departmentunit/getAll", { id })
        .then((response) => {
          this.items = response.data;
          this.items.forEach((item) => {
            item.isBlock = item.admin.isBlock;
            item.id = item.admin.id
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    blockUser(blockData) {
      this.loading = true;
      const id = blockData.id;
      const data = {
        reason: blockData.reason,
        model_type: 'App\\Models\\Admin\\Admin',
        type: blockData.type
      };
      this.$store
        .dispatch("admins/blockAdmin", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.fetch();
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>