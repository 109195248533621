<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <!-- <router-link to="/faq" class="btn btn-sm btn-primary mb-3">Back</router-link> -->
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Privacy Policy</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label>Content <span class="text-danger">*</span></label>
                            <QuillEditor v-model="policy.content" :editorHeight="'400px'" :editorWidth="'800px'" />
                           
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/quilleditor.vue";

export default {
    components:{
        QuillEditor,
    },
    data() {
        return {
            policy:{
                content:"",
            },
            loading:false,
        };
    },
    mounted() {
       
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("privacy/getAll")
                .then((response) => {
                    if (Object.keys(response.data).length === 0) {
                        this.policy.content="";
                    }else{
                        this.policy = response.data;
                    }          
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                 
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.policy);
            this.$store
                .dispatch("privacy/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },


    },
};
</script>