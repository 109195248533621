import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import auth from "./modules/auth";
import admins from "./modules/admins";
import users from "./modules/users";
import roles from "./modules/roles";
import module from "./modules/module";
import districts from "./modules/districts";
import officers from "./modules/officers";
import department from "./modules/department";
import departmentOfficer from "./modules/departmentOfficer";
import departmentunit from "./modules/departmentunit";
import status from "./modules/status";
import complaint from "./modules/complaint";
import country from "./modules/country";
import province from "./modules/province";
import form from "./modules/form";
import sops from "./modules/sops";
import faqs from "./modules/faq";
import privacy from "./modules/privacy";
import termandcondition from "./modules/termandcondition";
import feedback from "./modules/feedback";
import tehsil from "./modules/tehsil";
import area from "./modules/areas";
import qualification from "./modules/qualification";
import notification from "./modules/notification";
import howTo from "./modules/howTo";
import web from "./modules/webContent";
import story from "./modules/stories";
import news from "./modules/news";
import dashboard from "./modules/dashbaord";
import reporting from "./modules/reporting";
import TrackPerformace from "./modules/trackPerformace";


export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    layout,
    menu,
    auth,
    admins,
    users,
    roles,
    module,
    districts,
    officers,
    department,
    departmentOfficer,
    departmentunit,
    status,
    complaint,
    country,
    province,
    form,
    sops,
    faqs,
    privacy,
    feedback,
    tehsil,
    area,
    qualification,
    notification,
    howTo,
    web,
    story,
    dashboard,
    termandcondition,
    reporting,
    news,
    TrackPerformace
  },
});
