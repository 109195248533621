<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/dynamic-form" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ title }}</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label for="">Enter Form Title</label>
                            <input type="text" class="form-control form-control-sm" v-model="title" />
                            <div class="mt-3" v-for="(question, index) in allQuestions" :key="index">
                                <label class="me-3">Question {{ index + 1 }} <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm" v-model="question.question" />
                                <div class="row mt-3">
                                    <strong>Option Type</strong>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option1-' + index"
                                                value="radio" v-model="question.type">
                                            <label class="form-check-label" :for="'option1-' + index">Radio</label>
                                        </div>
                                    </div>
                                    <div class="col-auto mt-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" :id="'option2-' + index"
                                                value="text" v-model="question.type">
                                            <label class="form-check-label" :for="'option2-' + index">Text</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3" v-if="question.type == 'radio'">
                                    <div class="row">
                                        <div class="col-md-6" v-for="(option, oIndex) in question.options"
                                            :key="oIndex">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <strong>Option {{ oIndex + 1 }}</strong>
                                               
                                            </div>
                                            <input type="text" v-model="option.option" class="form-control"
                                                placeholder="Option">
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3" v-if="question.type === 'radio' && question.options.length > 0">
                                    <input type="checkbox" class="me-1" v-model="question.isRequired"
                                        :id="'requiredInput-' + index" />
                                    <label :for="'requiredInput-' + index">Required</label>
                                </div>                             
                                <div class="mt-2" v-if="allQuestions && allQuestions.length > 1">
                                    <hr>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
export default {
    mixins: [isNumber],
    data() {
        return {

            provinceId: null,
            countries: [],
            admin: [],
            title: "",
            loading: false,
        };
    },
    mounted() {
        this.provinceId = this.$route.params.id;
        this.fetch(this.provinceId);
        this.fetchCountry();
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("form/getSingle", { id })
                .then((response) => {
                    this.title = response.data.title;
                    this.allQuestions = response.data.question.map(item => ({
                        question: item.question,
                        type: item.type,
                        isRequired: item.isRequired,
                        options: item.options.map(opt => ({
                            option: opt.option
                        }))
                    }));
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.admin);
            const id = this.provinceId;
            this.$store
                .dispatch("users/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        fetchCountry() {
            this.loading = true;
            this.$store
                .dispatch("country/getAll")
                .then((response) => {
                    this.countries = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        }
    },

};
</script>