<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">All Countries</h5>
                            </div>
                            <div class="col-6 text-end">
                                <router-link to="/country/add" class="btn btn-sm"
                                    v-if="permissions && permissions.includes('country-Add')"
                                    style="background-color: rgb(255, 67, 42); color:white;">Add Country</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
                            :items="items" @deleted="fetch" :editable="isedit" :deleteable="isdelete"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "Name",
                    value: "name",
                },
            ],
            module: "country",
            loading: false,
            isedit: false,
            isdelete: false,
            searchBar: true,
            pagination: true,
            permissions:[],
        };
    },
    created() {
        this.fetch();
        this.permissions = localStorage.getItem("permissions");
        this.isedit = (this.permissions && this.permissions.includes("country-Edit")) ? true : false;
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$store
                .dispatch("country/getAll")
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>