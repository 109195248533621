<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-title">Anonymous Feedbacks</h5>
                            </div>
                            <div class="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :module="module" :pagination="pagination" :searchBar="searchBar"
                            :delete="delete" :isViewAble="isViewAble" :items="items" @deleted="fetch" @search="fetch"
                            @clear="fetch" @nextPage="nextPage"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "User Name",
                    value: "user_profile",
                    secondValue: "name"
                },
                {
                    text: "Phone",
                    value: "user",
                    secondValue: "phone"
                },
               
                {
                    text: "Department",
                    value: "department",
                    secondValue: "name"
                },

                {
                    text: "Feedback",
                    value: "feedback",
                },

            ],
            loading: false,
            edit: true,
            delete: true,
            searchBar: true,
            pagination: true,
            editable: true,
            isViewAble: true,
            module: "anonymous-feedback"

        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(search = "") {
            this.loading = true;
            this.$store
                .dispatch("feedback/getAllanonymousFeedback", { search, paginate: true })
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        nextPage(url) {
            this.loading = true;
            axiosInstance.get(`${url}&paginate=true`).then((response) => {
                this.items = response.data.data;
                this.loading = false;
            });
        },
    }
};
</script>