import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAllAdmins({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-all-admins`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getAdminData({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .post("/get-track-performance-data", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getDepartmentOfficers({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/get-departmt-officers/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    trackPerformance({ dispatch, commit }, id) {
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/track-performance/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
  },
};
