<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-8 mt-5 mx-auto">
                <router-link to="/sops" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Sop</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required />
                            <button type="button" class="btn btn-primary btn-sm mb-3" @click="addsop"> Add Sop</button>
                            <template v-for="(sop, index) in sops.sop">
                                <h6>Sop {{ index + 1 }}</h6>
                                <div>
                                    <label>Title <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm mb-3" required />
                                    <div class="row">
                                        <div class="col-6">
                                            <label>Number Of days <span class="text-danger">*</span></label>
                                            <input type="number" class="form-control form-control-sm mb-3" required />
                                        </div>
                                        <div class="col-6">
                                            <label>Sequence Number <span class="text-danger">*</span></label>
                                            <input type="number" class="form-control form-control-sm mb-3" required />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            sops: {
                name: null,
                sop: [],
            },
            sop: {
                title: "",
                days: "",
                sort: "",
            },

            loading: false,
        };
    },
    methods: {
        addsop() {
            this.sops.sop.push(Object.assign({}, this.sop));
        }
    },
};
</script>
  