<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">App Users</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="app-users/add" v-if="permissions && permissions.includes('appUsers-Add')"
                  class="btn btn-sm btn-primary mb-2 float-end">Add User</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" @blockUser="blockUser" :editable="editable"
              :pagination="pagination" :searchBar="searchBar" :isAdminBlock="isAdminBlock" :isViewAble="isViewAble"
              :items="items" @deleted="fetchUsers" @search="fetchUsers" @clear="fetchUsers" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [

      ],
      columns: [
        {
          text: "Name",
          value: "profile",
          secondValue: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Cnic",
          value: "cnic",
        }

      ],
      module: "users",
      permissions: null,
      isViewAble: false,
      isBlock: true,
      editable: true,
      isAdminBlock: true,
      searchBar: true,
      pagination: true,
      isAdminBlock: true,
      permissions: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchUsers();
    this.permissions = localStorage.getItem("permissions");
    this.editable = (this.permissions && this.permissions.includes("appUsers-Edit")) ? true : false;
    this.isAdminBlock = (this.permissions && this.permissions.includes("appUsers-Block")) ? true : false;
  },
  methods: {
    fetchUsers(search = "") {
      this.loading = true;
      this.$store
        .dispatch("users/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
          this.items.forEach((item) => {
            item.name = item.profile.name || '';
          });

        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    blockUser(blockData) {
      this.loading = true;
      const id = blockData.id;
      const data = {
        reason: blockData.reason,
        model_type: 'App\\Models\\User',
        type: blockData.type,
      };
      this.$store
        .dispatch("admins/blockAdmin", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.fetchUsers();
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    nextPage(url,search="") {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>