<template>
  <div class="dropdown">
    <button
      class="btn dropdown-toggle"
      id="dropdownMenuButton"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Today
    </button>
    <div
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownMenuButton"
    >
      <a class="dropdown-item" href="#">Today</a
      ><a class="dropdown-item" href="#">Tomorrow</a
      ><a class="dropdown-item" href="#">Yesterday </a>
    </div>
  </div>
</template>
<style scoped>
.dropdown .dropdown-toggle {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 20px !important;
  padding: 1px 10px !important;
}
</style>
