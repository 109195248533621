<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header card-no-border">
            <div class="row">
              <div class="col-6">

                <div
                  v-if="role == 'super-admin' && isActive(['/complaints', '/complaints?type=Total+Complaints', '/complaints?type=against_cmc_officers'])">
                  <ul class="nav nav-tabs" id="complaintTab" role="tablist">
                    <li class="nav-item">
                      <router-link class="nav-link"
                        :class="{ activeLiTab: isActive(['/complaints', '/complaints?type=Total+Complaints']) }"
                        to="/complaints" id="complaint-title-tab" role="tab" aria-controls="complaint-title"
                        aria-selected="true">
                        All Complainants
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link"
                        :class="{ activeLiTab: isActive(['/complaints?type=against_cmc_officers',]) }"
                        to="/complaints?type=against_cmc_officers" id="cmc-officers-tab" role="tab"
                        aria-controls="cmc-officers" aria-selected="false">
                        Complaint Against CMC Officers
                      </router-link>
                    </li>
                  </ul>
                </div>

                <div v-else>
                  <h5 class="card-title">
                    {{ formattedComplaintTitle }}
                  </h5>
                </div>


              </div>
              <div class="col-6 text-end">
                <router-link to="/complaint/add" v-if="permissions && permissions.includes('complaint-Add')"
                  class="btn btn-sm" style="background-color: rgb(255, 67, 42); color:white;">Add
                  Complaint</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">


            <button class="btn d-none" type="button" data-bs-toggle="modal" id="modalBtn"
              v-bind:data-bs-target="'#showCMCDetails'">
            </button>


            <div class="tab-content mt-3"
              v-if="role == 'super-admin' && isActive(['/complaints', '/complaints?type=Total+Complaints', '/complaints?type=against_cmc_officers'])">
              <div class="tab-pane fade"
                :class="{ show: isActive(['/complaints', '/complaints?type=Total+Complaints']), active: isActive(['/complaints', '/complaints?type=Total+Complaints']) }"
                id="complaint-title" role="tabpanel" aria-labelledby="complaint-title-tab">

                <data-table :columns="columns" :module="module" :items="items" :isChangePassword="changePassword"
                  :viewable="viewable" :pagination="pagination" :searchBar="searchBar" @deleted="fetch" @search="fetch"
                  @clear="fetch" @nextPage="nextPage"></data-table>

              </div>
              <div class="tab-pane fade"
                :class="{ show: isActive(['/complaints?type=against_cmc_officers',]), active: isActive(['/complaints?type=against_cmc_officers',]) }"
                id="cmc-officers" role="tabpanel" aria-labelledby="cmc-officers-tab">

                <data-table :columns="columnsForCMC" :module="module" :items="items" :isChangePassword="changePassword"
                  :cmsComliant="cmsComliant" :pagination="pagination" :searchBar="searchBar" @deleted="fetch"
                  @search="fetch" @clear="fetch" @nextPage="nextPage" @details="openModalDetails"></data-table>

              </div>
            </div>

            <div v-else>

              <data-table :columns="columns" :module="module" :items="items" :isChangePassword="changePassword"
                :viewable="viewable" :pagination="pagination" :searchBar="searchBar" @deleted="fetch" @search="fetch"
                @clear="fetch" @nextPage="nextPage"></data-table>



            </div>














          </div>
        </div>
      </div>







    </div>



    <div class="modal fade" id="showCMCDetails" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detail</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ cmcDetails.complaint_detail ?? "" }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      cmsComliant: true,
      cmcDetails: "",
      viewable: false,
      columns: [
        {
          text: "Complainant Name",
          value: "user_profile",
          secondValue: "name",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Date",
          value: "complaint_date",
        },
        {
          text: "Status",
          value: "complaintStatus",
        },
        {
          text: "Reference No.",
          value: "referenceNo",
        },
      ],
      columnsForCMC: [
        {
          text: "Complainant Name",
          value: "user_profile",
          secondValue: "name",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Date",
          value: "complaint_date",
        },
        {
          text: "Officer Name",
          value: "cmc_officer_name",
        },
      ],
      items: [],
      complaintType: "",
      module: "complaint",
      loading: false,
      searchBar: true,
      pagination: true,
      permissions: [],
      role: localStorage.getItem('role'),
    };
  },
  computed: {
    formattedComplaintTitle() {
      if (this.complaintType) {
        return this.complaintType
          .toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      } else {
        return 'All Complaints';
      }
    }
  },
  mounted() {
    this.complaintType = this.$route.query.type;
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.viewable = (this.permissions && this.permissions.includes("complaint-Edit")) ? true : false;
    if (this.role == 'Department-Head') {
      this.columns.push({
        text: "Priority",
        value: "priority",
      });
    }
    if (this.complaintType == 'against_cmc_officers') {
      this.columns = this.columns.filter(column => column.text !== "Status");
    }
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("complaint/getAll", { search, paginate: true, type: this.complaintType })
        .then((response) => {
          this.items = response.data;
          // this.items = this.items.data.filter(item => item.status.name != "In-progress");
          this.items.data.forEach((item) => {
            if (item.complaintStatus == "Close") {
              item.complaintStatus = "Closed";
            }
          });

          if(this.role=='super-admin')
          {
            this.items.data.forEach((item) => {
            if (item.complaintStatus == "Not Assigned") {
              item.complaintStatus = "Active";
            }
          });
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    isActive(routes) {
      return routes.some(route => this.$route.fullPath == route);
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}&type=${this.complaintType}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        // this.items = this.items.data.filter(item => item.status.name !== "In-progress");
        this.items.data.forEach((item) => {
          if (item.complaintStatus == "Close") {
            item.complaintStatus = "Closed";
          }
        });
        if(this.role=='super-admin')
          {
            this.items.data.forEach((item) => {
            if (item.complaintStatus == "Not Assigned") {
              item.complaintStatus = "Active";
            }
          });
          }

        this.loading = false;
      });
    },
    openModalDetails(item) {
      this.cmcDetails = item;
      const modal = document.getElementById("modalBtn");
      modal.click();
    }
  },
};
</script>

<style scoped>
.nav-item .nav-link {
  padding: 10px 15px;
  border-radius: 4px;
}

.activeLiTab {
  background-color: #0468B1 !important;
  color: white !important;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 1px solid #0468B1;
  border-bottom: 0px;
  border-radius: px;
  margin: 1px;
}
</style>