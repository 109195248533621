<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <router-link to="/roles" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label class="primary-text-color">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" required v-model="role.name" />
              <table class="table mt-3">
                <tbody>
                  <tr>
                    <td class="checkbox-label">
                      <input type="checkbox" id="checkAll" @change="toggleCheckAll">
                      <label for="checkAll" style="margin-left: 5px;">Check All</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Admin</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-1" v-model="role.permissions"
                        :value="getName('admin', 'View')">
                      <label for="addCheckbox-1">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-2" v-model="role.permissions"
                        :value="getName('admin', 'Add')">
                      <label for="addCheckbox-2">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-4" v-model="role.permissions"
                        :value="getName('admin', 'Delete')">
                      <label for="deleteCheckbox-4">Delete</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-888" v-model="role.permissions"
                        value="admin-changePassword">
                      <label for="addCheckbox-888">Change Password</label>
                    </td>
                  </tr>


                  <tr>
                    <td class="checkbox-label"></td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-888" v-model="role.permissions" value="blockAdmin">
                      <label for="addCheckbox-888">Block</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-888" v-model="role.permissions"
                        value="admin-Edit">
                      <label for="addCheckbox-888">Edit </label>
                    </td>
                  </tr>




                  <tr>
                    <td style="font-weight: bold; width:12rem;">Roles</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-6" v-model="role.permissions"
                        :value="getName('role', 'View')">
                      <label for="addCheckbox-6">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-7" v-model="role.permissions"
                        :value="getName('role', 'Add')">
                      <label for="addCheckbox-7">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-8" v-model="role.permissions"
                        :value="getName('role', 'Edit')">
                      <label for="editCheckbox-8">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-9" v-model="role.permissions"
                        :value="getName('role', 'Delete')">
                      <label for="deleteCheckbox-9">Delete</label>
                    </td>
                  </tr>
              
                  <tr>
                    <td style="font-weight: bold; width:12rem;">District Officer</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-18" v-model="role.permissions"
                        :value="getName('districtOfficer', 'View')">
                      <label for="addCheckbox-18">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-19" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Add')">
                      <label for="addCheckbox-19">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3004" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Edit')">
                      <label for="addCheckbox-3004">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3005" v-model="role.permissions"
                        :value="getName('districtOfficer', 'Block')">
                      <label for="addCheckbox-3005">Block</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Department Officers</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-26" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'View')">
                      <label for="addCheckbox-26">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-27" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Add')">
                      <label for="addCheckbox-27">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3000" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Edit')">
                      <label for="addCheckbox-3000">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3001" v-model="role.permissions"
                        :value="getName('departmentOfficers', 'Block')">
                      <label for="addCheckbox-3001">Block</label>
                    </td>
                  </tr>
                  
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Complaints</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-36" v-model="role.permissions"
                        :value="getName('complaint', 'View')">
                      <label for="addCheckbox-36">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-78963" v-model="role.permissions"
                        :value="getName('complaint', 'Add')">
                      <label for="addCheckbox-78963">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-12378" v-model="role.permissions"
                        :value="getName('complaint', 'Edit')">
                      <label for="editCheckbox-12378">Edit</label>
                    </td>

                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Manage SOP</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-40" v-model="role.permissions"
                        :value="getName('sop', 'View')">
                      <label for="addCheckbox-40">View</label>
                    </td>

                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-42" v-model="role.permissions" value="sop-Update">
                      <label for="editCheckbox-42">Update</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Dynamic Form</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-406756757" v-model="role.permissions"
                      :value="getName('sopForm', 'Add')">
                      <label for="addCheckbox-406756757">View</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">App Users</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-100" v-model="role.permissions"
                        :value="getName('appUsers', 'View')">
                      <label for="addCheckbox-100">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3040" v-model="role.permissions"
                        :value="getName('appUsers', 'Add')">
                      <label for="addCheckbox-3040">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-101" v-model="role.permissions"
                        :value="getName('appUsers', 'Edit')">
                      <label for="addCheckbox-101">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-3020" v-model="role.permissions"
                        :value="getName('appUsers', 'Block')">
                      <label for="addCheckbox-3020">Block</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Track Performance</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-200" v-model="role.permissions"
                        :value="getName('trackPerformance', 'View')">
                      <label for="addCheckbox-200">View</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold; width:12rem;">Notification</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-203" v-model="role.permissions"
                        :value="getName('notification', 'View')">
                      <label for="addCheckbox-203">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-204" v-model="role.permissions" value="notification-Send">
                      <label for="addCheckbox-204">Send</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold; width:12rem;">Web Pages</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-7000" v-model="role.permissions" value="webContent">
                      <label for="addCheckbox-7000">Access</label>
                    </td>
                    <!-- <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-71" v-model="role.permissions"
                      value="webContent-Add">
                    <label for="addCheckbox-71" >Add</label>
                    </td> -->
                  </tr>
                  <tr>
                    <td style="font-weight: bold; width:12rem;">Setting
                      <i class="fa fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="This setting allows all operations of Country, Province, District, Tehsil, and Tehsil Area modules."></i>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-8596" v-model="role.permissions" value="setting">
                      <label for="addCheckbox-8596">Access</label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";

export default {
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.fetchModules();
  },
  methods: {
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      this.$store
        .dispatch("roles/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.role.name = "";
            this.role.permissions = [];
            document.querySelector('input[type="checkbox"]#checkAll').checked = false;
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });

    },
    getName(module, action) {
      const permission = this.modules.find(permission => permission.name === `${module}-${action}`);
      return permission ? permission.name : null;
    },
    toggleCheckAll(event) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(#checkAll)');
      checkboxes.forEach(checkbox => {
        checkbox.checked = event.target.checked;
        if (checkbox.checked && !this.role.permissions.includes(checkbox.value)) {
          this.role.permissions.push(checkbox.value);
        } else if (!checkbox.checked) {
          const index = this.role.permissions.indexOf(checkbox.value);
          if (index !== -1) {
            this.role.permissions.splice(index, 1);
          }
        }
      });
    },
    clearAll() {
      this.role.name = "";
      this.role.permissions = [];
      document.querySelector('input[type="checkbox"]#checkAll').checked = false;
    }
  },
};
</script>

<style>
.checkbox-label input[type="checkbox"] {
  margin-right: 2px;
}

.table td {
  padding: 0.5rem;
}

.checkbox-label label {
  display: inline-block;
}

.table> :not(caption)>*>* {
  border-bottom-width: 0 !important;
}

.low-margin {
  width: 15% !important;
}
</style>
