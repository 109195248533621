<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="page-wrapper">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <div class="login-card">
              <div>
                <div>
                  <a class="logo">
                    <img class="img-fluid for-light" src="@/assets/images/logo/logo-green.png" alt="looginpage" />
                  </a>
                </div>
                <div class="login-main" v-if="!error">
                  <form class="theme-form" @submit.prevent="submit">
                    <h4>Reset Password</h4>
                    <div class="alert alert-danger" v-if="errors.confirmPassword">
                      <span class="d-block">{{ errors.confirmPassword }}</span>
                    </div>
                    <div class="form-group mt-3">
                      <label class="col-form-label">New Password</label>
                      <div class="d-flex align-items-center mb-3"
                        style="border-bottom: 2px solid #0468B1; padding-bottom: 5px; position: relative;">
                        <i class="fa fa-key" style="margin-right: 10px;"></i>
                        <input class="form-control form-control-sm border-0" :type="active ? 'password' : 'text'"
                          placeholder="********" v-model="password" required autofocus
                          style="margin-left: 10px; border: none; outline: none; box-shadow: none;" />
                        <div class="show-hide" @click="show" style="position: absolute; right: 0; cursor: pointer;">
                          <span :class="active ? 'show' : 'hide'">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label class="col-form-label">Confirm Password</label>
                      <div class="d-flex align-items-center mb-3"
                        style="border-bottom: 2px solid #0468B1; padding-bottom: 5px; position: relative;">
                        <i class="fa fa-key" style="margin-right: 10px;"></i>
                        <input class="form-control form-control-sm border-0" :type="active ? 'password' : 'text'"
                          placeholder="********" v-model="confirmPassword" required autofocus
                          style="margin-left: 10px; border: none; outline: none; box-shadow: none;" />
                        <div class="show-hide" @click="show" style="position: absolute; right: 0; cursor: pointer;">
                          <span :class="active ? 'show' : 'hide'">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <button class="btn btn-sm btn-primary btn-block">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
                <div v-else>
                  <div class="card">
                    <div class="card-body">
                      <p v-if="error.token" class="text-danger">
                        {{ error.token }}
                      </p>
                      <p v-if="error.link" class="text-danger">
                        {{ error.link }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  data() {
    return {
      active: true,
      password: null,
      confirmPassword: null,
      token: null,
      msg: null,
      error: null,
      errors: [],
      loading: false,
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
    if (this.$route.query.t) {
      this.token = this.$route.query.t;
    } else {
      this.error.token = "Invalid token";
    }
    this.verifyToken();
  },
  methods: {
    submit() {
      if (this.password != this.confirmPassword) {
        this.errors.confirmPassword = "Password didn't match.";
      } else {
        this.loading = true;
        const data = {
          token: this.token,
          password: this.password,
        };
        this.$store
          .dispatch("auth/resetPassword", { data })
          .then((response) => {
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Successfuly updated.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.$nextTick();
              router.push({ path: "/auth/login" });
            }
          })
          .catch((e) => {
            this.error = e.error;
            this.loading = false;
          });
      }
    },
    show() {
      this.active = !this.active;
    },
    verifyToken() {
      this.loading = true;
      const data = {
        token: this.token,
      };
      this.$store
        .dispatch("auth/verifyResetToken", { data })
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.error;
          this.loading = false;
        });
    },
  },
};
</script>
