<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-title">FAQs</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/faq/add" class="btn btn-sm"
                  style="background-color: rgb(255, 67, 42); color:white;">Add
                  FAQ</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :module="module" :editable="edit" :deleteable="delete" :items="items" :searchBar="true"
              @deleted="fetch" @search="fetch" @clear="fetch" @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [


      ],
      columns: [
        {
          text: "Question",
          value: "question",
        },
        {
          text: "Answer",
          value: "answer",
        },
      ],

      loading: false,
      edit: true,
      delete: true,
      module: "faqs",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("faqs/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true`).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>