<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/how-to" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit How To Content</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit">

                            <label class="mt-2">Title</label>
                            <input type="text" v-model="howto.title" class="form-control  mb-3" required />


                            <label>Description <span class="text-danger">*</span></label>
                            <QuillEditor v-model="howto.description" :editorHeight="'400px'" :editorWidth="'800px'" />

                            <label class="mt-2">Attach Audio</label>
                            <input type="file" class="form-control form-control-sm mb-3" accept="audio/*"
                                @change="handleFileChange"  />

                            <div class="audio-preview" v-if="audioSrc">
                                <audio :src="audioSrc" controls></audio>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/quilleditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            howto: [],
            audioSrc: null,
            id: null,
            loading: false,
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetch(this.id);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("howTo/getSingle", { id })
                .then((response) => {
                    this.howto = response.data;
                    this.audioSrc=this.howto.audio_file.image_url;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.howto);
            const id = this.id;
            this.$store
                .dispatch("howTo/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const base64String = e.target.result;
                     this.howto.audio=base64String;
                    this.audioSrc = e.target.result;
                };

                reader.readAsDataURL(file);
            }
        },
    },
};
</script>