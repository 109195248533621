<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="card-title">All District Officer</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/district-officer/add" class="btn btn-sm"
                  v-if="permissions && permissions.includes('districtOfficer-Add')"
                  style="background-color: rgb(255, 67, 42); color:white;">Add District Officer</router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :pagination="pagination" :searchBar="searchBar" :editable="editable"
              @blockUser="blockUser" :isAdminBlock="isAdminBlock" :module="module" :items="items"
              :isChangePassword="changePassword" @deleted="fetch" @search="fetch" @clear="fetch"
              @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";


export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      columns: [
        {
          text: "Name",
          value: "admin",
          secondValue: "f_name",

        },
        {
          text: "Email",
          value: "admin",
          secondValue: "email",

        },
        {
          text: "District",
          value: "district",
          secondValue: "name",

        },
        {
          text: "Type",
          value: "officer_type",
        },
      ],
      items: [],
      module: "officers",
      loading: false,
      isAdminBlock: true,
      editable: true,
      searchBar: true,
      searchBar: true,
      pagination: true,
      permissions: [],
    };
  },
  mounted() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.editable = (this.permissions && this.permissions.includes("districtOfficer-Edit")) ? true : false;
    this.isAdminBlock = (this.permissions && this.permissions.includes("districtOfficer-Block")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      console.log("fetch");
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("officers/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.items.data.forEach(item => {
            item.isBlock = item.admin.isBlock;
            item.id = item.admin_id
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    blockUser(blockData) {
      this.loading = true;
      const id = blockData.id;
      const data = {
        reason: blockData.reason,
        model_type: 'App\\Models\\Admin\\Admin',
        type: blockData.type
      };
      this.$store
        .dispatch("admins/blockAdmin", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.fetch();
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true&search=${search}`).then((response) => {
        this.items = response.data.data;
        this.items.data.forEach(item => {
            item.isBlock = item.admin.isBlock;
            item.id = item.admin_id
          });
        this.loading = false;
      });
    },
  },
};
</script>