<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-header card-no-border">

            <div class="row">
              <div class="col-6">
                <h5 class="card-title">Dynamic Forms</h5>
              </div>
              <div class="col-6 text-end">
                <router-link to="/question/add" class="btn btn-sm"
                  style="background-color: rgb(255, 67, 42); color:white;">Add Form</router-link>
              </div>
            </div>


          </div>
          <div class="card-body">

            <data-table :columns="columns" :module="module" :items="items" :isChangePassword="changePassword"
              :editable="editable" :isViewAble="isViewAble" :viewDataforms="viewDataforms" :searchBar="searchBar"
              :pagination="pagination" @deleted="fetch" @search="fetch" @clear="fetch"
              @nextPage="nextPage"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      searchValue: "",
      viewable: true,
      columns: [

        {
          text: "Title",
          value: "title",
        },
      ],
      items: [],
      module: "form",
      loading: false,
      isViewAble: true,
      editable: false,
      searchBar: true,
      viewDataforms: false,
      pagination: true,

    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("form/getAll", { search,paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(`${url}&paginate=true`).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>