<template>
  <router-link to="/">
    <!-- <img
      class="img-fluid for-light"
      src="../../assets/images/logo/logo.jpeg"
      alt=""
      style="width: 160px"
    /> -->
    <h5 style="color: #FFFFFF;">SEP5 Logo</h5>
  </router-link>
</template>
<script>
export default {
  name: "Logo",
};
</script>
