<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/news" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit News</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addDistrict">

                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="news.title" />

                            <label>District <span class="text-danger">*</span></label>
                            <select class="custom-select mb-3" v-model="news.district_id" required>
                                <option value="">Select...</option>
                                <option v-for="district in districts" :value="district.id">
                                    {{ district.name }}
                                </option>
                            </select>

                            <label>Description <span class="text-danger">*</span></label>
                            <textarea type="text" class="form-control form-control-sm mb-3" rows="7" required
                                v-model="news.news"></textarea>


                            <div class="row">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            news: [],
            storyId: null,
            loading: false,
            districts: [],
        };
    },
    mounted() {
        this.storyId = this.$route.params.id;
        this.fetch(this.storyId);
        this.fetchDistricts();

    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("news/getSingle", { id })
                .then((response) => {
                    this.news = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.news);
            const id = this.storyId;
            this.$store
                .dispatch("news/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        fetchDistricts() {
            this.loading = true;
            this.$store
                .dispatch("districts/getAll")
                .then((response) => {
                    this.districts = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },

    },

};
</script>